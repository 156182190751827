import {useAuthStore} from "./modules/auth";
import {useOrganisationsStore} from "./modules/organisations";

const fetchMyOrganisations = async () => {
	const auth = useAuthStore();
	const organisationsStore = useOrganisationsStore();

	if (auth.organisations.length  && auth.permissions.view_own_data) {
		await Promise.all(
			auth.organisations.map(async (organisation) => {
				await organisationsStore.fetchById(organisation);
			})
		);
	}
};

const fetchRegionalOrgs = async () => {
	const auth = useAuthStore();
	const organisationsStore = useOrganisationsStore();

	if (auth.regions.length && auth.permissions.view_regional_data) {
		await organisationsStore.fetchBy(
			"region",
			"array-contains-any",
			auth.regions
		);
	}
};

export {fetchMyOrganisations, fetchRegionalOrgs};
