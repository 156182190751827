<template>
	<v-sheet flat>
		<!-- <v-switch
			label="Include all rows"
			color="primary"
			v-model="includeAll"></v-switch> -->
		<div>
			<template v-if="['section', 'theme', 'service'].includes(prop)">
				<p>
					Choose which assessment {{ prop }}s to include from the options shown
					below. More information on data structure can be found in the
					Tutorials section. When you're done, click NEXT.
				</p>
				<panel-selection
					v-model="selected"
					:options="options"
					multiple></panel-selection>
			</template>

			<template v-if="'question' == prop">
				<p>
					Choose which questions from each assessment section to include from
					the options shown below. More information on data structure can be
					found in the Tutorials section. When you're done, click NEXT
				</p>
				<v-expansion-panels class="mt-3">
					<v-expansion-panel
						elevation="0"
						v-for="(arr, section) in questions"
						:key="section">
						<v-expansion-panel-title>
							<span style="font-size: 0.8em">
								{{ sectionsStore.getLabel(section) }}
							</span>
						</v-expansion-panel-title>
						<v-expansion-panel-text>
							<v-table density="compact">
								<thead>
									<tr>
										<td colspan="2">
											<v-switch
												label="Select All Questions in Section"
												hide-details=""
												color="success"
												@input="(e) => toggleAll(arr, e)" />
										</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in arr" :key="item.question">
										<td>
											<v-checkbox
												v-model="selected"
												:value="item"
												density="compact"
												hide-details />
										</td>
										<td>
											{{ item.title }}
										</td>
									</tr>
								</tbody>
							</v-table>
						</v-expansion-panel-text>
					</v-expansion-panel>
				</v-expansion-panels>
			</template>
		</div>
	</v-sheet>
</template>

<script setup>
	import _ from "lodash";
	import {useQuestionsStore} from "@/stores/modules/questions";
	import {defineProps, ref, computed, defineEmits, watch} from "vue";
	import {getLabels, allQuestionsOrdered} from "@/stores/getters";
	import PanelSelection from "@/components/data-query-wizard/PanelSelection";
	import {useSectionsStore} from "@/stores/modules/sections";
	const props = defineProps({
		prop: String,
		modelValue: Object
	});
	const $emit = defineEmits(["update:modelValue"]);
	const questionsStore = useQuestionsStore();
	const sectionsStore = useSectionsStore();
	const labels = computed(() => getLabels(props.prop));
	const options = computed(() => {
		let options = Object.keys(labels.value).map((value) => ({
			value,
			title: labels.value[value]
		}));
		options = options.filter((o) => o.value !== "null");
		if (!options || options.length == 0) {
			options = [];
		}
		return options;
	});
	const questions = computed(() => {
		let questions = allQuestionsOrdered();
		questions = questions.map((item) => ({
			value: item.question,
			title: questionsStore.getLabel(item.question),
			section: item.section
		}));
		return _.groupBy(questions, "section");
	});
	const selected = ref([]);
	// const includeAll = ref(true);
	const toggleAll = (arr, e) => {
		let values = arr.map((a) => a.value);
		if (e.target.checked) {
			selected.value = arr;
		} else {
			selected.value = selected.value.filter((v) => !values.includes(v.value));
		}
	};

	watch(selected, (value) => {
		$emit("update:modelValue", value);
	});
</script>
