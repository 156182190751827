<template>
	<dashboard-card :title="title" :color="color">
		<p v-if="body">{{ body }}</p>
		<template v-if="display == 'number'">
			<v-row class="text-center mt-4">
				<v-col>
					<p class="text-h1">
						{{
							isNaN(avg(Object.values(nationalScores)))
								? "-"
								: `${avg(Object.values(nationalScores))}%`
						}}
					</p>
					<p>National Average</p>
				</v-col>
				<v-col v-if="organisationId">
					<p class="text-h1">
						{{
							isNaN(avg(Object.values(organisationScores)))
								? "-"
								: `${avg(Object.values(organisationScores))}%`
						}}
					</p>
					<p>{{ organisation.name }}</p>
				</v-col>
			</v-row>
		</template>
		<template v-if="display == 'chart'">
			<h-bar-chart
				:data="chartData"
				style="background-color: white"
				height="600px"></h-bar-chart>
		</template>
	</dashboard-card>
</template>

<script setup>
	import _ from "lodash";
	import {defineProps, computed, onMounted} from "vue";
	import {useQuestionsStore} from "@/stores/modules/questions";
	import {useOrganisationsStore} from "@/stores/modules/organisations";
	import {useRowsStore} from "@/stores/modules/rows";
	import DashboardCard from "@c/ui/DashboardCard";
	import HBarChart from "@/components/charts/HBarChart.vue";
	const props = defineProps({
		title: String,
		organisationId: String,
		color: {type: String, default: "lightest"},
		questions: Array,
		body: String,
		display: {type: String, default: "chart"}
	});

	const questionsStore = useQuestionsStore();
	const rowsStore = useRowsStore();
	onMounted(() => {
		props.questions.forEach((q) => questionsStore.fetchById(q));
	});
	const organisationsStore = useOrganisationsStore();
	const organisation = computed(
		() => organisationsStore.keyed[props.organisationId]
	);

	const avg = (arr) => {
		return Math.ceil(_.mean(arr));
	};

	const getAverages = (arr) => {
		return Object.fromEntries(
			props.questions.map((question) => {
				let rows = arr.filter((row) => question == row.question);
				return [question, avg(rows.map((row) => row.value))];
			})
		);
	};

	const nationalScores = computed(() => {
		let data = rowsStore.data["national_average"] || [];
		return getAverages(data);
	});

	const organisationScores = computed(() => {
		let data = rowsStore.data[`${props.organisationId}-latest-score`] || [];
		return getAverages(data);
	});

	const chartData = computed(() => {
		let d = {
			labels: props.questions.map((q) =>
				questionsStore.getLabel(q).match(/\b[\w']+(?:[^\w\n]+[\w']+){0,5}\b/g)
			),
			datasets: [
				{
					label: "National Average",
					data: Object.values(nationalScores.value),
					backgroundColor: "#193758"
				}
			]
		};
		if (props.organisationId) {
			d.datasets.push({
				label: organisation.value.name,
				data: Object.values(organisationScores.value),
				backgroundColor: "#009B55"
			});
		}
		return d;
	});
</script>
