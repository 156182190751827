import _ from "lodash";
export default {
	masterSlide: {
		title: "MASTER_SLIDE",
		background: {color: "FFFFFF"},
		objects: [
			{
				image: {
					x: 11.3,
					y: 0.1,
					w: 1.97,
					h: 0.75,
					path: require("@/assets/logo.png")
				}
			},
			{
				image: {
					x: 11.3,
					y: 6.4,
					w: 1.67,
					h: 0.75,
					path: require("@/assets/mwlogo.png")
				}
			}
		],
		slideNumber: {x: 0.3, y: "90%"}
	},
	makeDatapoints({rows, series, value}) {
		return rows.flatMap((row) => {
			return series.map((s) => {
				return {
					column: s,
					value: row[s],
					[value]: row[value]
				};
			});
		});
	},
	makeDatasets({type, datapoints, names = {}, countBy, groupBy}) {
		let groups = _.uniq(datapoints.map((d) => d[groupBy]));
		let series = _.uniq(datapoints.map((d) => d[countBy]));
		let labels = series.map((s) => names[s] || s);
		let datasets = groups.map((g, i) => {
			let label = names[g] || g;
			let data = series.map((s) => {
				let datapoint = datapoints.find(
					(dp) => dp[groupBy] == g && dp[countBy] == s
				);
				return datapoint ? datapoint.value : 0;
			});
			return {
				label,
				data, 
				...this.getColors(type, i, labels.length)
			};
		});

		return {
			labels,
			datasets
		};
		// let allData = input.data.countBy(input.countBy);
	},
	getColors(type,i, len){
		const colors = [
			"#193758",
			"#009B55",
			"#E2371F",
			"#009FE3",
			"#F8F400",
			"#64BE28",
			"#78007A",
			"#F1AF00",
			"#C00067",
			"#00775B",
			"darkgrey", 
			"green-lighten-3",
			"blue-lighten-3",
			"red-lighten-3",
			"orange-lighten-3",
			"yellow-lighten-3",
			"purple-lighten-3",
			"grey-lighten-3",
			"pink-lighten-3",
			"green-lighten-5",
			"blue-lighten-5",
		]
		if( ["donut", "pie"].includes(type) ){
			return {backgroundColor: colors.slice(0, len)}
		}
		if( ["line"].includes(type) ){
			return {borderColor: colors[i], backgroundColor: colors[i]}
		}

		return {backgroundColor: colors[i]}
	}
};
