<template>
	<v-sheet>
		<v-text-field label="Search" v-model="search"> </v-text-field>
		<v-card color="#cccccc" v-if="errorState">
			<v-card-text style="text-align: center">
				You can only aggregate numerical data (e.g., scores). Answers are
				text-based data and cannot be aggregated.
			</v-card-text>
		</v-card>
		<v-data-table
			v-else
			ref="mainTable"
			:headers="headers"
			:items="rows"
			density="compact"
			:search="search"
			v-model="dashboard.selected">
			<!-- eslint-disable vue/valid-v-slot -->
			<template v-slot:item.section="{item}">
				{{ sectionsStore.getLabel(item.value.section) }}
			</template>
			<template v-slot:item.service="{item}">
				{{ servicesStore.getLabel(item.value.service) }}
			</template>
			<template v-slot:item.theme="{item}">
				{{ themesStore.getLabel(item.value.theme) }}
			</template>
			<template v-slot:item.question="{item}">
				{{ questionsStore.getLabel(item.value.question) }}
			</template>

			<template
				v-for="{value} in headers"
				v-slot:[`column.${value}`]="{column}">
				{{ column.title }}
				<v-tooltip v-if="column.description" location="top" :key="value">
					<template v-slot:activator="{props}">
						<v-icon v-bind="props">mdi-information</v-icon>
					</template>
					{{ column.description }}
				</v-tooltip>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<style lang="less">
	.v-table {
		font-size: 0.8em;
	}
</style>
<script setup>
	import {computed, ref} from "vue";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useSectionsStore} from "@/stores/modules/sections";
	import {useThemesStore} from "@/stores/modules/themes";
	import {useQuestionsStore} from "@/stores/modules/questions";
	import {useServicesStore} from "@/stores/modules/services";
	import {useColumnsStore} from "@/stores/modules/columns";
	const columnsStore = useColumnsStore();
	const dashboard = useDashboardStore();
	const columns = computed(() => columnsStore.data );
	const headers = computed(() => {
		return columns.value
			.filter((column) => column.display)
			.map((header) => {
				header.key = header.value;
				return header;
			});
	});
	const search = ref("");
	const sectionsStore = useSectionsStore();
	const questionsStore = useQuestionsStore();
	const themesStore = useThemesStore();
	const servicesStore = useServicesStore();
	const rows = computed(() => dashboard.filteredData);

	const displayedSeries = computed(() => columnsStore.displayedSeries);

	const aggregated = computed(() => {
		return headers.value.find((h) => h.value == "question") ? false : true;
	});

	const notQuantifiable = computed(() => {
		return displayedSeries.value.find((s) => !s.is_score) ? true : false;
	});

	const errorState = computed(() => {
		return notQuantifiable.value && aggregated.value;
	});
</script>
