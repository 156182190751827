<template>
	<v-card flat color="transparent">
			<v-checkbox
				v-for="column in timeSeriesStore.columnsForOrg"
				:key="column.value"
                multiple
				v-model="timeSeriesStore.columns"
                :label="column.title"
                :value="column"
                hide-details="">
			</v-checkbox>
	</v-card>
</template>

<script setup>
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";

	const timeSeriesStore = useTimeSeriesStore();
</script>
