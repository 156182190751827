import {defineStore} from "pinia";

export const useQuestionsStore = defineStore("questions", () => {
	// settings for the collection plugin
	const type = "collection";
	const collectionName = "questions";
	const loadAll = false;

	const defaultValues = {
		status: "published"
	}

	const label = (row) => {
		let val = `${row.question_text}`;
		if( row.subquestion_text) {
			val += " - " 
			val +=row.subquestion_text;
		}
		return val;
	}
	return {
		options: {
			type,
			collectionName,
			loadAll
		}, 
		label,
		defaultValues,
	};
});
