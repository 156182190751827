<template>
    
    <v-sheet v-if="content">
        <h1 class="mb-4 font-weight-light" v-html="content.title"></h1>
        <div v-html="content.html"></div>
    </v-sheet>
</template>

<script setup>

    import { useContentStore } from "@/stores/modules/content";
import { computed, onMounted } from "vue";


    const contentStore = useContentStore();

    onMounted( () => {
        contentStore.fetchById('datasupport')
    })

    const content = computed(() => {
        return contentStore.keyed['datasupport']
    }) 
    content
</script>