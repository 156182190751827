<template>
	<v-tooltip v-if="canStack" location="top" text="Stack chart">
		<template v-slot:activator="{props}">
			<v-btn
				v-bind="props"
				icon
				:color="modelValue ? 'success' : 'grey'"
				@click="$emit('update:modelValue', !modelValue)">
				<v-icon>mdi-chart-bar-stacked</v-icon>
			</v-btn>
		</template>
	</v-tooltip>
</template>

<script setup>
	import {defineProps, computed} from "vue";
	const props = defineProps({
		type: String,
		modelValue: Boolean
	});
	const canStack = computed(() => ["bar", "hbar"].includes(props.type));
</script>
;
