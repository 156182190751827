<template>
	<v-card flat color="transparent">
		<span>Aggregate By:</span>
		<v-radio-group v-model="timeSeriesStore.aggregateBy">
			<v-radio value="theme" label="Theme" density="compact"> </v-radio>
			<v-radio value="section" label="Section" density="compact">
			</v-radio> </v-radio-group
		>
		<filter-aggregates></filter-aggregates>
		<v-divider></v-divider>
		<v-switch
			class="ml-1"
			v-model="timeSeriesStore.includeService"
			density="compact"
			label="Include Service"
			hide-details>
		</v-switch>
		<v-switch
			class="ml-1"
			v-model="timeSeriesStore.includeNationalAverage"
			density="compact"
			label="Include National Average"
			hide-details>
		</v-switch>

	</v-card>
</template>

<script setup>
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";
	import FilterAggregates from "@c/time-series/FilterAggregates";

	const timeSeriesStore = useTimeSeriesStore();
</script>
