<template>
	<v-item-group
		:model-value="value"
		@update:modelValue="(v) => $emit('input', v)">
		<v-item
			v-slot="{isSelected, toggle}"
			v-for="item in items"
			:key="item.value"
			:value="item.value">
			<v-tooltip location="top" :text="item.mouseover">
				<template v-slot:activator="{props}">
					<v-btn
						v-bind="props"
						icon
						:color="isSelected ? 'success' : 'grey'"
						@click="toggle">
						<v-icon>{{ item.icon }}</v-icon>
					</v-btn>
				</template>
			</v-tooltip>
		</v-item>
		<v-item></v-item>
	</v-item-group>
</template>

<script setup>
	import {defineProps} from "vue";
	defineProps({
		value: String
	});

	let items = [
		{value: "bar", mouseover: "Bar Chart", icon: "mdi-chart-bar"},
		{value: "hbar", mouseover: "Horizontal Bar Chart", icon: "mdi-chart-gantt"},
		{value: "line", mouseover: "Line Chart", icon: "mdi-chart-line"},
		{value: "pie", mouseover: "Pie Chart", icon: "mdi-chart-pie"},
		{value: "doughnut", mouseover: "Doughnut Chart", icon: "mdi-chart-donut"}
	];
</script>
;
