import _ from "lodash";
import {defineStore} from "pinia";
import {useRowsStore} from "./rows";
import {computed, ref, watch} from "vue";
import chartUtils from "@/assets/chartUtils";
import {getLabels} from "@/stores/getters";
import {useColumnsStore} from "./columns";
import {useOrganisationsStore} from "./organisations";
import {useConfigStore} from "@/stores/modules/config";
import {useQuestionsStore} from "@/stores/modules/questions";
import {useNationalAverageStore} from "./nationalAverages";
export const useTimeSeriesStore = defineStore("timeSeries", () => {
	const organisation = ref(null);
	const nationalAveragesStore = useNationalAverageStore();
	const organisationsStore = useOrganisationsStore();
	const rowsStore = useRowsStore();
	const columnsStore = useColumnsStore();
	const includeNationalAverage = ref(false);
	const includeService = ref(false);
	const aggregateBy = ref("section");
	const columns = ref([]);
	const columnsForOrg = computed(() => {
		return columnsStore.data.filter(
			(c) => c.organisation == organisation.value && c.is_score
		);
	});
	watch(columnsForOrg, (v) => {
		columns.value = v;
	});

	const addNationalAverageColumns = () => {
		columns.value
			.filter((c) => c.source == "organisation")
			.forEach(async (column) => {
				let id = await nationalAveragesStore.fetchBefore(
					[],
					new Date(column.updated_at)
				);
				nationalAverages.value.push(id);
			});
	};

	const resetFilters = () => {
		filter.value = {
			section: [],
			theme: []
		};
	};

	const setFiltersToOneOnly = () => {
		let first = filter.value[aggregateBy.value].length
			? filter.value[aggregateBy.value][0]
			: null;
		if (!first) {
			first = rows.value[0][aggregateBy.value];
		}
		resetFilters();
		filter.value = {
			[aggregateBy.value]: [first]
		};
	};

	const filter = ref({section: [], theme: []});

	const nationalAverages = ref([]);

	const removeNationalAverageColumns = () => {
		nationalAverages.value = [];
	};
	watch(includeNationalAverage, (v) => {
		removeNationalAverageColumns();
		if (v) {
			includeService.value = false;
			setFiltersToOneOnly();
			addNationalAverageColumns();
		}
	});

	watch(includeService, (v) => {
		if (v) {
			includeNationalAverage.value = false;
			// setFiltersToOneOnly();
		}
	});
	const rows = computed(() => {
		let values = columns.value.map((c) => c.value);
		values = [...values, ...nationalAverages.value];
		return rowsStore.process(
			values,
			filter.value,
			aggregateBy.value,
			includeService.value
		);
	});

	const active = computed(() => {
		return columns.value.length > 1;
	});

	watch(organisation, (id) => {
		organisationsStore.includeOrganisation(organisationsStore.keyed[id], false);
	});

	const chronological = computed(() => {
		return _.sortBy(columns.value, "updated_at").reverse();
	});
	const earliest = computed(() => {
		if (!chronological.value.length) {
			return null;
		}
		return chronological.value[chronological.value.length - 1].updated_at;
	});
	const latest = computed(() => {
		if (!chronological.value.length) {
			return null;
		}
		return chronological.value[0].updated_at;
	});
	const options = computed(() => {
		return {
			scales: {
				x: {
					min: earliest.value,
					max: latest.value,
					ticks: {
						source: "data"
					},
					type: "time",
					time: {
						unit: "week"
					}
				},
				y: {
					min: 0,
					max: 100
				}
			}
		};
	});

	const labels = computed(() => {
		let labels = getLabels(aggregateBy.value);
		delete labels.null;
		return labels;

	});
	const serviceLabels = computed(() => {
		return getLabels("service");
	});

	const datasets = computed(() => {
		let d = [];
		rows.value.forEach((row, i) => {
			let label = labels.value[row[aggregateBy.value]];
			if (includeService.value && row.service !== "null") {
				label += " - ";
				label += serviceLabels.value[row.service];
			}
			if (includeNationalAverage.value) {
				label += " - Your Selection";
			}
			d.push({
				label,
				type: "line",
				data: columns.value.map((column) => {
					return {
						x: column.updated_at,
						y: row[column.value]
					};
				}),
				...chartUtils.getColors("line", i, rows.value.len)
			});
			if (includeNationalAverage.value) {
				d.push({
					label: `${labels.value[row[aggregateBy.value]]} - National Average`,
					data: nationalAverages.value.map((column) => {
						let columnInfo = columnsStore.getColumn(column);
						return {
							x: columnInfo.updated_at,
							y: row[column]
						};
					}),
					...chartUtils.getColors("bar", 1, 2)
				});
			}
		});
		return d;
	});

	watch(aggregateBy, () => {
		includeNationalAverage.value = false;
		resetFilters();
	});

	const start = async () => {
		const config = useConfigStore();
		const questionsStore = useQuestionsStore();
		config.watchDoc();
		await questionsStore.fetchAll();
	};

	const end = () => {
		resetFilters();
		includeNationalAverage.value = false;
		includeService.value = false;
		organisation.value = null;
		columnsStore.visibleColumns.forEach((c) => (c.display = false));
		columnsStore.getColumn("section").display = true;
	};

	return {
		start,
		aggregateBy,
		organisation,
		columns,
		nationalAverages,
		columnsForOrg,
		filter,
		rows,
		end,
		active,
		chronological,
		earliest,
		latest,
		datasets,
		options,
		labels,
		includeNationalAverage,
		includeService,
		serviceLabels
	};
});
