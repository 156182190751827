import {defineStore} from "pinia";
import { useQuestionsStore } from "./questions";

export const useSectionsStore = defineStore("sections", () => {
	// settings for the collection plugin
	const type = "collection";
	const collectionName = "sections";
	const loadAll = false;

	const defaultValues = {
		status: "published"
	}

	const questionsStore = useQuestionsStore();
	const onDocumentAdded = (doc) => {
		doc.questions.forEach( question => {
			questionsStore.fetchById(question);
		})
	}
	return {
		options: {
			type,
			collectionName,
			loadAll
		}, 
		defaultValues,
		onDocumentAdded
	};
});
