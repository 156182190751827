import _ from "lodash";
import {defineStore} from "pinia";
import {ref} from "vue";
import {allQuestionsOrdered} from "@/stores/getters";
import { useColumnsStore } from "./columns";
// import {useOrganisationsStore} from "./organisations.js";
// import {useAnswersStore} from "./answers.js";
// import {useColumnsStore} from "./columns.js";
// import {useNationalAverageStore} from "./nationalAverages.js";
export const useRowsStore = defineStore("rows", () => {
	// const organisationsStore = useOrganisationsStore();
	// const answersStore = useAnswersStore();
	// const columnsStore = useColumnsStore();
	// const nationalAverageStore = useNationalAverageStore();

	const data = ref({});

	const add = (id, values) => {
		data.value[id] = values;
	};

	const filterValues = (data, filters) => {
		for (var key in filters) {
			let v = Object.values(filters[key]);
			data = data.filter((row) => {
				if( !v.length ){
					return true;
				}
				return v.includes( row[key] );
			});
		}
		return data;
	};

	const orderByAssessment = (values) => {
		let questions = allQuestionsOrdered();
		questions = questions.filter((question) => question);
		let order = questions.map((q) => q.question);
		return _.sortBy(values, (item) => {
			return order.indexOf(item.question);
		});
	};

	const getThemes = () => {
		let questions = allQuestionsOrdered();
		questions = questions.filter((question) => question);
		let themes = questions.map((q) => [q.section, q.theme]);
		themes = _.uniq(themes);
		themes = Object.fromEntries(themes);
		return themes;
	};

	const process = (columns, filters, aggregateBy, includeServices) => {
		{
			columns, filters, aggregateBy, includeServices;
		}

		// Only process columns we have data for
		columns = columns.filter((c) => data.value[c]);

		// get themes to apply by section
		const themes = getThemes();
		let values = columns.flatMap((column) => {
			let values = data.value[column];
			// reassign column values to the column id
			values = values.map((v) => ({
				section: v.section,
				theme: themes[v.section],
				question: v.question,
				service: v.service,
				[column]: v.value
			}));
			// only process data which matches the filters
			values = filterValues(values, filters);
			return values;
		});
		// group rows by question/section/service combo
		values = _.groupBy(
			values,
			(v) => `${v.section}-${v.question}-${v.service}`
		);
		// merge column values into single object e.g. {section, service, question, col1, col2, col3}
		values = Object.values(values).map((v) =>
			v.reduce((x, y) => _.merge(x, y), {})
		);
		// put in the assessment order
		values = orderByAssessment(values);
		// group by the relevant column for calculating aggregate value
		values = _.groupBy(values, (row) =>
			includeServices ? `${row[aggregateBy]}-${row.service}` : row[aggregateBy]
		);

		// construct row object
		values = Object.values(values).map((arr) => {
			let row = {
				theme: themes[arr[0].section]
			};
			if (aggregateBy !== "theme") {
				row.section = arr[0].section;
			}
			row[aggregateBy] = arr[0][aggregateBy];
			if (includeServices) {
				row.service = arr[0].service;
			}
			const columnsStore = useColumnsStore();
			let averages = Object.fromEntries(columns.map((c) => [c, null]));
			for (var key in averages) {
				averages[key] = arr.map((c) =>
					typeof c[key] !== "undefined" ? c[key] : null
				);
				averages[key] = averages[key].filter(
					(a) => ![null, false, "-", "null", ""].includes(a)
				);
				if (averages[key].length > 0) {
					let columnDefinition = columnsStore.getColumn(key);
					if( columnDefinition.is_score ){
						averages[key] = _.mean(averages[key]);
						averages[key] = Math.ceil(averages[key]);
					}
					else {
						averages[key] = averages[key].join(", ")
					}
				} else {
					averages[key] = "-";
				}
			}
			return {
				...row,
				...averages
			};
		});

		return values;
	};
	return {
		add,
		data,
		process
	};
});
