import {defineStore} from "pinia";

export const useThemesStore = defineStore("themes", () => {
	// settings for the collection plugin
	const type = "collection";
	const loadAll = false;

	return {
		options: {
			type,
			loadAll, 
			collectionName: "categoryOptions"
		}, 
	};
});
