<template>
	<v-sheet flat width="100%" style="font-size: 0.8em" class="mt-4">
		<v-row>
			<v-col>
				<v-card variant="outlined" class="pb-12">
					<v-list density="compact" bg-color="grey-lighten-2">
						<v-list-item
							border="grey"
							v-for="option in available"
							:key="option[optionValue]"
							color="grey-lighten-3"
							:disabled="disabled"
							@click="select(option)">
							{{ option[optionTitle] }}
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col>
				<v-card flat variant="outlined" height="100%">
					<v-list density="compact" color="transparent">
						<template v-if="multiple">
							<v-list-item
								v-for="option in modelValue"
								:key="option[optionValue]"
								color="grey-lighten-3"
								:value="option[optionValue]">
								{{ option[optionTitle] }}
								<template v-slot:append>
									<v-btn size="x-small" icon @click="deselect(option)">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</template>
							</v-list-item>
						</template>
						<v-list-item v-if="!multiple && modelValue" color="grey-lighten-3">
							{{ modelValue.title }}
							<template v-slot:append>
								<v-btn
									size="x-small"
									icon
									@click="$emit('update:modelValue', null)">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</template>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script setup>
	import {defineProps, computed, defineEmits} from "vue";

	const props = defineProps({
		disabled: {type: Boolean, default: false},
		multiple: {type: Boolean, default: true},
		modelValue: [Array, Object],
		options: {
			type: Array,
			default: () => ([])
		},
		optionTitle: {type: String, default: 'title'},
		optionValue: {type: String, default: 'value'}
	});

	const $emit = defineEmits(["update:modelValue"]);

	const available = computed(() => {
		if (props.multiple) {
			return props.options.filter(
				(a) => !props.modelValue.find((item) => item.value == a.value)
			);
		} else {
			if (!props.modelValue) {
				return props.options;
			} else {
				return props.options.filter((a) => props.modelValue.value !== a.value);
			}
		}
	});

	const select = (option) => {
		let value = option;
		if (props.multiple) {
			value = [...props.modelValue, option];
		}
		$emit("update:modelValue", value);
	};
	const deselect = (option) => {
		let value = props.modelValue;
		value = value.filter((e) => e.value !== option.value);
		$emit("update:modelValue", value);
	};

</script>
