<template>
    <v-list-item @click="auth.logout">
        <template v-slot:prepend>
            <v-icon>mdi-logout-variant</v-icon>
        </template>
        <v-list-item-title>Log out</v-list-item-title>
    </v-list-item>
</template>
<script setup>
import { useAuthStore } from '@/stores/modules/auth';

const auth = useAuthStore();

</script>