<template>
	<v-list-item>
		<template v-slot:prepend>
			<v-icon v-if="!auth.isLoggedIn">mdi-account</v-icon>
			<v-avatar v-else color="primary">{{initials}}</v-avatar>
		</template>
		<v-btn v-if="!auth.isLoggedIn" block color="success" @click="dialog = true">
			Log In
		</v-btn>
		<v-list-item-title  v-else>
			<span> Welcome {{ auth.name }} </span>
		</v-list-item-title>		
			<log-in v-model="dialog"></log-in>
	</v-list-item>
		
</template>

<script async setup>
	import {ref, computed} from "vue";
	import {useAuthStore} from "@/stores/modules/auth";
	import LogIn from "@c/auth/LogIn";
	const auth = useAuthStore();
	const dialog = ref(false);
	const initials = computed(() => {
		return auth.name.split(" ").map( n => n.charAt(0)).join("")
	})
</script>
