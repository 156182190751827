<template>
	<v-sheet>
		<p>Choose the level of data aggregation from the options shown below. More information on data structure can be found in the Tutorials section. When you're done, click NEXT.</p>
		<panel-selection
            :multiple="false"
			:options="options"
			:model-value="modelValue"
			@update:modelValue="
				(v) => $emit('update:modelValue', v)
			"></panel-selection>
	</v-sheet>
</template>

<script setup>
	import {computed, defineProps} from "vue";
	import {useColumnsStore} from "@/stores/modules/columns";
	import PanelSelection from "@/components/data-query-wizard/PanelSelection";

	defineProps({modelValue: Object});
	const columns = useColumnsStore();

	const options = computed(() => columns.labels);
</script>
