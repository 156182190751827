<template>
	<v-sheet flat class="mt-12">
		<v-form v-if="!sent">
			<v-text-field label="Email Address" v-model="email"></v-text-field>
			<v-textarea label="Your Message" v-model="message"></v-textarea>
			<p class="text-right">
				<v-btn color="primary" @click="submit">Submit</v-btn>
			</p>
		</v-form>
		<v-alert color="lightest" v-else>
			Thanks. Your message has been sent.
		</v-alert>
	</v-sheet>
</template>

<script setup>
	import {useAuthStore} from "@/stores/modules/auth";
	import {useEmailsStore} from "@/stores/modules/emails";
	import {ref, watch} from "vue";

	const sent = ref(false);
	const email = ref("");
	const message = ref("");

	const auth = useAuthStore();

	watch(
		auth,
		(value) => {
			if (value.email) {
				email.value = value.email;
			}
		},
		{immediate: true, deep: true}
	);

	const submit = async () => {
		const emailsStore = useEmailsStore();

		const doc = {
			to: "sg@dma.works",
			message: {
				subject: `New contact form from RESULTS site from: ${email.value}`,
				html: message.value
			},
			type: "contact_form"
		};
		await emailsStore.create(doc);
		sent.value = true;
	};
</script>
