import {httpsCallable} from "firebase/functions";
import {functions} from "../../../mwFirebase.js";
import {defineStore} from "pinia";
import _ from "lodash";
import {useColumnsStore} from "./columns.js";
import {useRowsStore} from "./rows.js";
import {useServicesStore} from "./services.js";
import {useAnswersStore} from "./answers.js";
import {useDashboardStore} from "./dashboard.js";
export const useOrganisationsStore = defineStore("organisations", () => {
	// settings for the collection plugin

	const oneOrganisationsDataMany = httpsCallable(
		functions,
		"oneOrganisationsDataMany"
	);

	const fetchData = async (id) => {
		if (id) {
			let results = await oneOrganisationsDataMany({id});
			return JSON.parse(results.data);
		}
	};

	const columnsStore = useColumnsStore();
	const answersStore = useAnswersStore();
	const dashboard = useDashboardStore();

	const includeOrganisation = async (org, addCol) => {
		if (!org) {
			return;
		}
		org.data = [];
		dashboard.startLoading();
		const results = await fetchData(org.id);
		const rowsStore = useRowsStore();
		let all = Object.keys(results).map((id) => ({id, ...results[id]}));
		all.forEach((row) => (row.updated_at = new Date(row.updated_at)));
		all = _.sortBy(all, "updated_at");
		all = all.reverse();
		all.forEach((res, i) => {
			res.latest = i == 0;
			let id = res.latest ? "latest" : res.updated_at.toLocaleDateString();
			let label = _.capitalize(id);
			org.data.push(res);

			columnsStore.add({
				value: `${org.id}-${id}-score`,
				title: `${org.name} - ${label} Score`,
				display: res.latest && addCol,
				is_score: true,
				source: "organisation",
				organisation: org.id,
				description: res.description,
				updated_at: res.updated_at
			});
			columnsStore.add({
				value: `${org.id}-${id}-answer`,
				title: `${org.name} - ${label} Answer`,
				organisation: org.id,
				source: "organisation",
				display: false,
				is_score: false,
				description: res.description,
				updated_at: res.updated_at
			});
			rowsStore.add(
				`${org.id}-${id}-score`,
				res.data.map((v) => ({
					value: v.average,
					question: v.question,
					section: v.section,
					service: v.service
				}))
			);
			rowsStore.add(
				`${org.id}-${id}-answer`,
				res.data.map((v) => ({
					value: Object.keys(v.counts)
						.map((answer) => answersStore.getLabel(answer))
						.join(", "),
					question: v.question,
					section: v.section,
					service: v.service
				}))
			);
		});
		dashboard.endLoading();
	};

	const servicesStore = useServicesStore();
	const onDocumentAdded = (doc) => {
		if (doc.service) {
			doc.service.forEach((id) => {
				servicesStore.fetchById(id);
			});
		}
	};

	const removeOrganisation = (id) => {
		dashboard.includedOrganisations = dashboard.includedOrganisations.filter(
			(o) => o.value !== id
		);
		columnsStore.data.forEach((col) => {
			if (col.organisation == id) {
				columnsStore.remove(col.value);
			}
		});
	};
	return {
		options: {
			type: "collection",
			collectionName: "organisations",
			loadAll: false
		},
		fetchData,
		includeOrganisation,
		onDocumentAdded,
		removeOrganisation
	};
});
