import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
loadFonts()
import {firebaseApp} from "../mwFirebase";
import { VueFire, VueFireAuth } from 'vuefire'
// import * as Sentry from "@sentry/vue";

const app = createApp(App);
// Sentry.init({
// 	app,
// 	dsn: "https://2f4253e0e9f841858d5549cfba57ecc9@o397452.ingest.sentry.io/4505387477237760",
// 	integrations: [
// 		new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
// 			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router)
// 		}),
// 		new Sentry.Replay()
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
app.use(router)
.use(store)
.use(vuetify)
.use(VueFire, {
  firebaseApp: firebaseApp,
  modules: [
    // ... other modules
    VueFireAuth(),
  ],
}).mount('#app')



export { app, firebaseApp }