import _ from "lodash";
import {
	computed,
	ref
	// watch
} from "vue";
import {defineStore} from "pinia";
import {useRowsStore} from "./rows";

export const useColumnsStore = defineStore("columns", () => {
	const rowsStore = useRowsStore();
	const data = ref([
		{
			value: "theme",
			key: "theme",
			title: "Theme",
			display: false,
			description: "",
			updated_at: null
		},
		{
			value: "section",
			key: "section",
			title: "Section",
			display: true,
			description: "",
			updated_at: null
		},
		{
			value: "question",
			key: "question",
			title: "Question",
			display: false,
			description: "",
			updated_at: null
		},
		{
			value: "service",
			key: "service",
			title: "Service",
			display: false,
			description: "",
			updated_at: null
		}
	]);
	const aggregateColumns = ref([]);

	const getColumn = (id) => {
		return data.value.find((a) => a.value == id);
	};

	const visibleColumns = computed(() =>
		data.value.filter((column) => column.display)
	);

	const dataLabelOptions = ref(["question", "service", "section", "theme"]);
	const dataSeriesOptions = ref(["national_average"]);

	const labels = computed(() => {
		return data.value.filter((item) =>
			dataLabelOptions.value.includes(item.value)
		);
	});
	const series = computed(() => {
		return data.value.filter((item) =>
			dataSeriesOptions.value.includes(item.value)
		);
	});

	const displayedLabels = computed(() => {
		return visibleColumns.value.filter((item) =>
			dataLabelOptions.value.includes(item.value)
		);
	});
	const displayedSeries = computed(() => {
		return visibleColumns.value.filter((item) =>
			dataSeriesOptions.value.includes(item.value)
		);
	});

	const add = ({
		value,
		title,
		is_score,
		organisation,
		display,
		description,
		updated_at,
		source = "national"
	}) => {
		if( getColumn(value)) {
			return;
		}
		data.value.push({
			value,
			organisation,
			title,
			is_score,
			display,
			description,
			updated_at,
			source
		});
		dataSeriesOptions.value.push(value);
	};

	const columnIsVisible = (v) => {
		return visibleColumns.value.find((column) => column.value == v)
			? true
			: false;
	};

	const visibleScoredColumns = computed(() => {
		return visibleColumns.value.filter((column) => column.is_score);
	});

	const setColumns = (arr) => {
		data.value.forEach((column) => {
			if (arr.includes(column.value)) {
				column.display = true;
			} else {
				column.display = false;
			}
		});
	};

	const getLatest = (dates) => {
		dates = dates.sort();
		dates = dates.reverse();
		return dates[0];
	};

	const isValidScore = (v) => {
		if (v == 0 || v > 0) {
			return true;
		}
		return false;
	};
	const newAggregateColumn = ({title, members = [], description}) => {
		const id = _.uniqueId();
		aggregateColumns.value.push({id, title, members});
		add({
			value: id,
			title,
			display: true,
			is_score: true,
			description,
			updated_at: getLatest(members.map((d) => getColumn(d).updated_at)),
			source: "aggregate"
		});
		const membersValues = members.map((m) => rowsStore.data[m]);

		let values = membersValues.reduce(
			(x, y) =>
				x.concat(
					y.map((a) => ({
						...a,
						k: `${a.section}-${a.question}-${a.service}`,
						value: a.value
					}))
				),
			[]
		);
		values = _.groupBy(values, "k");
		values = Object.values(values);
		values = values.map((value) => {
			let scores = value.map((v) => v.value);
			scores = scores.filter((v) => isValidScore(v));
			let mean = _.mean(scores);
			if( isNaN(mean ) ){
				mean = "-";
			}
			return {...value[0], value: mean };
		});
		rowsStore.add(id, values);
	};

	const remove = (id) => {
		data.value = data.value.filter( col => col.value !== id )
	}

	return {
		data,
		add,
		remove,
		getColumn,
		aggregateColumns,
		visibleColumns,
		labels,
		series,
		displayedLabels,
		dataLabelOptions,
		displayedSeries,
		setColumns,
		columnIsVisible,
		newAggregateColumn,
		visibleScoredColumns
	};
});
