<template>
	<v-sheet>
		<p>Choose which services to include from the options shown below. Assessment data collected without reference to specific services us available under option "General". More information on data structure can be found in the Tutorials section. When you're done, click NEXT. </p>
		<panel-selection
			:options="options"
			:model-value="modelValue"
			@update:modelValue="
				(v) => $emit('update:modelValue', v)
			"></panel-selection>
	</v-sheet>
</template>

<script setup>
    import {getLabels} from "@/stores/getters"
	import {computed, defineProps} from "vue";
	import PanelSelection from "@/components/data-query-wizard/PanelSelection";

	defineProps({modelValue: Array});
    const services = getLabels('service');
	const options = computed(() => Object.keys(services).map( value => ({value, title: services[value]})));
</script>
