<template>
	<v-dialog v-model="dialog" width="600">
		<v-card>
			<v-card-text>
				<panel-selection
					:model-value="selected"
					@update:model-value="(v) => updateFilter(v)"
					:options="options"
					:multiple="multiple"
					:return-object="false"></panel-selection>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="submit" color="success">Done</v-btn>
			</v-card-actions>
		</v-card>
		<template v-slot:activator="{props}">
			<v-btn size="small" color="success" v-bind="props" block
				>Edit {{ label }}</v-btn
			>
		</template>
	</v-dialog>
</template>

<script setup>
	import {computed, ref} from "vue";
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";
	import PanelSelection from "@c/data-query-wizard/PanelSelection";
	const timeSeriesStore = useTimeSeriesStore();
	const dialog = ref(false);

	const filter = computed(
		() => timeSeriesStore.filter[timeSeriesStore.aggregateBy]
	);

	const options = computed(() => {
		return Object.keys(timeSeriesStore.labels).map((value) => ({
			value,
			title: timeSeriesStore.labels[value]
		}));
	});

	let labels = timeSeriesStore.labels;
	labels;
	const updateFilter = (values) => {
		if (multiple.value) {
			timeSeriesStore.filter[timeSeriesStore.aggregateBy] = values.map(
				(v) => v.value
			);
		} else {
			timeSeriesStore.filter[timeSeriesStore.aggregateBy] = [values.value];
		}
	};
	const label = computed(() => {
		return timeSeriesStore.aggregateBy == "theme" ? "Themes" : "Sections";
	});
	const multiple = computed(() => {
		return timeSeriesStore.includeNationalAverage ? false : true;
	});

	const selected = computed(() => {
		let values = options.value.filter((l) =>
			filter.value.includes(l.value)
		);
		if (multiple.value) {
			return values;
		} else {
			if (values.length) {
				return values[0];
			}
			return options.value[0];
		}
	});

	const submit = () => {
		dialog.value = false;
	};
</script>
