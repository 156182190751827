<template>
	<v-card flat ref="viz">
		<v-toolbar elevation="0">
			<chart-types :value="type" @input="(v) => (type = v)" />

			<stack-chart :type="type" v-model="stacked"></stack-chart>
			<v-spacer></v-spacer>
			<switch-chart-groups
				v-if="canSwitch"
				v-model:groupBy="groupBy"
				v-model:countBy="countBy"></switch-chart-groups>
		</v-toolbar>
		<component
			:is="component"
			:id="id"
			:data="data"
			:title="title"
			:options="chartOptions"></component>
		<v-card-actions>
			<v-spacer></v-spacer>
		</v-card-actions>
	</v-card>
</template>

<script setup>
	import _ from "lodash";
	import {ref, defineProps, defineEmits, computed, watch} from "vue";

	import ChartTypes from "@c/charts/ChartTypes";
	import SwitchChartGroups from "@c/charts/SwitchChartGroups";
	import BarChart from "@c/charts/BarChart";
	import HBarChart from "@c/charts/HBarChart";
	import PieChart from "@c/charts/PieChart";
	import LineChart from "@c/charts/LineChart";
	import DoughnutChart from "@c/charts/DoughnutChart";
	import StackChart from "@c/charts/StackChart";

	import chartUtils from "@/assets/chartUtils";

	const props = defineProps({
		id: String,
		title: String,
		names: Object,
		options: {type: Object, default: () => ({})},
		datapoints: Array,
		defaultGroup: String, 
		displayedLabel: String, 
		displayedSeries: Array
	});

	const $emit = defineEmits(["update"]);
	
	const viz = ref(null);
	const stacked = ref(false);

	let type = ref("bar");
	let groupBy = ref(props.defaultGroup);
	let countBy = ref(props.displayedLabel);

	let indexes = computed(() => [props.defaultGroup, props.displayedLabel]);

	const reset = ([x, y]) => {
		groupBy.value = x;
		countBy.value = y;
	}

	watch( indexes, (value) => {
		reset(value);
	})

	let canSwitch = computed(() => {
		return props.displayedLabel.length > 1;
	});
	let data = computed(() => {
		return chartUtils.makeDatasets({
			type: type.value,
			groupBy: groupBy.value,
			countBy: countBy.value,
			datapoints: props.datapoints,
			names: props.names
		});
	});

	watch( data, value => {
		$emit("update", value )
	}, {immediate: true})

	let component = computed(() => {
		let c = {
			bar: BarChart,
			hbar: HBarChart,
			doughnut: DoughnutChart,
			line: LineChart,
			pie: PieChart
		};
		return c[type.value];
	});

	const chartOptions = computed(() =>{
		let options = {...props.options}
		if( stacked.value ){
			_.set(options, "scales.x.stacked", true)
			_.set(options, "scales.y.stacked", true)
		}
		else{
			_.set(options, "scales.x.stacked", false)
			_.set(options, "scales.y.stacked", false)
		}
		
		return options;
	});
</script>
