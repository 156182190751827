import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {useDashboardStore} from "./dashboard";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../mwFirebase.js";
import {useColumnsStore} from "./columns";
import {useRowsStore} from "./rows";
import { useConfigStore } from "./config";
computed;
export const useNationalAverageStore = defineStore("nationalAverage", () => {
	const dashboard = useDashboardStore();
	const config = useConfigStore();
	const data = ref([]);

	const allAssessmentAverages = httpsCallable(
		functions,
		"allAssessmentAverages"
	);
	const allAssessmentAveragesBefore = httpsCallable(
		functions,
		"allAssessmentAveragesBeforeDate"
	);

	const fetchLatest = async (v) => {
		dashboard.startLoading();
		const columnsStore = useColumnsStore();
		const rowsStore = useRowsStore();
		const exists = columnsStore.getColumn("national_average");
		if (exists) {
			return;
		}
		let results = await allAssessmentAverages(v);
		//TODO: BECCA - these functions pull in all data across all assessments. They should only pull in the
		/// relevant sections, rather than filtering for the relevant sections here
		// the relevant sections are defined as the sections which are 
		// shown for the current provider type
		// this is a temporary fix as it is the 10th October
		results.data = results.data.filter( v => config.relevantSections.includes(v.section)  );
		data.value.push({
			data: results.data,
			id: "national_average",
			latest: true,
			updated_at: new Date()
		});
		rowsStore.add(
			"national_average",
			results.data.map((v) => ({
				value: v.average,
				question: v.question,
				section: v.section,
				service: v.service
			}))
		);
		columnsStore.add({
			value: "national_average",
			key: "national_average",
			title: "National Average",
			source: "national",
			display: true,
			is_score: true,
			updated_at: new Date(),
			description: "An average of all available organisations"
		});
		dashboard.endLoading();
	};
	const fetchBefore = async (services, before) => {
		dashboard.startLoading();
		const columnsStore = useColumnsStore();
		const label = `national_average_${before.toISOString()}`;
		if( columnsStore.getColumn(label) ){
			return;
		}
		let results = await allAssessmentAveragesBefore({services, before});
		results.data = results.data.filter( v => config.relevantSections.includes(v.section)  );
		data.value.push({
			data: results.data,
			id: label,
			latest: false,
			updated_at: before
		});
		const rowsStore = useRowsStore();
		rowsStore.add(
			label,
			results.data.map((v) => ({
				value: v.average,
				question: v.question,
				section: v.section,
				service: v.service
			}))
		);
		columnsStore.add({
			value: label,
			key: label,
			title: `National Average - ${before.toLocaleDateString()}`,
			source: "national",
			display: true,
			is_score: true,
			updated_at: before,
			description: `An average of all available organisations at ${before.toLocaleDateString()}`
		});
		dashboard.endLoading();
		return label;
	};

	return {
		data,
		fetchLatest,
		fetchBefore
		// series
	};
});
