<template>
	<v-navigation-drawer location="right" permanent :rail="rail">
		<v-list>
			<v-list-item v-if="!rail">
				<v-expansion-panels v-model="panel">
					<expansion-panel-with-help
						color="success"
						icon="mdi-office-building"
						title="Organisation"
						help="Choose organisation">
						<v-autocomplete
							class="mt-2"
							color="white"
							variant="outlined"
							rounded
							label="Select Organisation"
							:items="organisationsStore.collection"
							item-value="id"
							item-title="name"
							v-model="timeSeriesStore.organisation"></v-autocomplete>
					</expansion-panel-with-help>
					<expansion-panel-with-help
						v-if="timeSeriesStore.columnsForOrg.length"
						color="primary"
						icon="mdi-target-variant"
						title="Data Points"
						help="">
						<select-columns></select-columns>
					</expansion-panel-with-help>
					<expansion-panel-with-help
						v-if="timeSeriesStore.columnsForOrg.length"
						color="primary"
						icon="mdi-gesture-tap"
						title="Detail"
						help="">
						<time-series-detail></time-series-detail>
					</expansion-panel-with-help>
				</v-expansion-panels>
				<v-btn class="mt-2" block color="grey" @click="reset">
					<v-icon class="mr-2">mdi-restore</v-icon>
					<span style="font-size: 0.7em"> Reset Query </span>
				</v-btn>
			</v-list-item>
		</v-list>
		<template v-slot:append>
			<v-list>
				<v-list-item v-if="!rail">
					<v-expansion-panels>
						<expansion-panel-with-help
							color="success"
							title="Download Options"
							icon="mdi-download">
							<chart-download-png id="timeSeriesViz"></chart-download-png>
							<download-csv :rows="rows" :columns="columns"></download-csv>
						</expansion-panel-with-help>
					</v-expansion-panels>
				</v-list-item>
				<v-list-item title="Collapse Menu" @click="rail = !rail">
					<template v-slot:prepend>
						<v-icon>mdi-menu</v-icon>
					</template>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script setup>
	import {computed, ref} from "vue";
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";
	import {useOrganisationsStore} from "@/stores/modules/organisations";

	import ExpansionPanelWithHelp from "@/components/ui/ExpansionPanelWithHelp.vue";
	import SelectColumns from "@/components/time-series/SelectColumns.vue";
	import TimeSeriesDetail from "@/components/time-series/TimeSeriesDetail.vue";
	// import AggregateColumn from "@/components/dashboard/AggregateColumn.vue";
	// import EditColumns from "@/components/dashboard/EditColumns.vue";
	// import ResetButton from "@/components/dashboard/ResetButton.vue";
	import ChartDownloadPng from "@c/downloads/ChartDownloadPng";
	import DownloadCsv from "@c/downloads/DownloadCsv";
	import {useColumnsStore} from "@/stores/modules/columns";
	// import QueryWizardDialog from "@c/data-query-wizard/QueryWizardDialog";
	const panel = ref(0);
	const rail = ref(false);
	const timeSeriesStore = useTimeSeriesStore();
	const columnsStore = useColumnsStore();
	const organisationsStore = useOrganisationsStore();
	// const organisationsStore = useOrganisationsStore();
	const rows = computed(() => timeSeriesStore.rows);
	const columns = computed(() => {
		let agg = columnsStore.getColumn(timeSeriesStore.aggregateBy);
		return [agg, ...timeSeriesStore.columns];
	});

	const reset = () => {
		timeSeriesStore.end();
	};
</script>
