import _ from "lodash";
import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {useSectionsStore} from "./sections";
import {useOrganisationsStore} from "./organisations";

export const useConfigStore = defineStore("config", () => {
	const collectionName = "config";
	const type = "doc";

	let id = ref("default");
	let sections = ref([]);
	let relevantSections = ref([]);
	let rules = ref([]);

	let providerTypes = computed(() => {
		let organisationsStore = useOrganisationsStore();
		if( !organisationsStore.collection ){
			return;
		}
		let types = organisationsStore.collection.flatMap(
			(organisation) => organisation.provider
		);
		return _.uniq(types);
	});

	const includeSections = () => {
		if (!providerTypes.value || !Object.keys(rules.value).length) {
			return false;
		}
		return sections.value.filter((section) => {
			let rule = rules.value[section].find(
				(rule) => rule.category == "provider"
			);
			if (rule) {
				return providerTypes.value.some((r) => rule.value.includes(r));
			}
		});
	};

	watch(
		[rules, providerTypes],
		() => {
			relevantSections.value = includeSections() || [];
		},
		{deep: true}
	);

	watch(relevantSections, (v) => {
		if( !v ){
			return;
		}
		const sectionsStore = useSectionsStore();
		v.forEach((id) => {
			sectionsStore.fetchById(id);
		});
	});
	return {
		id,
		options: {
			collectionName,
			type
		},
		sections,
		providerTypes,
		rules,
		relevantSections
	};
});
