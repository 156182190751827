<template>
	<v-sheet>
		<h1 class="font-weight-light">Delivery Plan Dashboard</h1>
		<v-card class="mb-5 mt-6" color="lightest" flat>
			<v-card-text>
				<p>
					The information below has been collated from your organisation's
					Digital Maturity Assessment results to assist you with the completion
					of your organisation's delivery plan submissions.
				</p>
				<p>
					For better guidance, the data follows the same structure as the
					delivery plan template, and headline requirements from the template
					are also included on this page.
				</p>
			</v-card-text>
		</v-card>
		<v-toolbar flat color="transparent">
			<v-spacer></v-spacer>
			<v-select
				v-if="organisations.length"
				:disabled="organisations.length == 1"
				:items="organisations"
				v-model="organisationId"
				item-value="id"
				item-title="name">
			</v-select>
		</v-toolbar>
		<loading-spinner :model-value="loading"></loading-spinner>
		<v-sheet v-if="auth.isLoggedIn && !loading">
			<delivery-plan-row
				v-for="(row, i) in rows"
				:key="i"
				:organisation-id="organisationId"
				v-bind="row"></delivery-plan-row>
		</v-sheet>
	</v-sheet>
</template>

<script setup>
	import {computed, ref, watch, onMounted} from "vue";
	import {useAuthStore} from "@/stores/modules/auth";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useOrganisationsStore} from "@/stores/modules/organisations";
	import LoadingSpinner from "@c/ui/LoadingSpinner";
	import DeliveryPlanRow from "@/components/ui/DeliveryPlanRow";
	const dashboard = useDashboardStore();
	const loading = computed(() => dashboard.loading);
	const auth = useAuthStore();
	const organisationsStore = useOrganisationsStore();
	const organisations = computed(() =>
		auth.organisations.map((id) => organisationsStore.keyed[id])
	);
	const organisationId = ref("");

	watch(
		organisations,
		(value) => {
			if (!value[0]) {
				return;
			}
			organisationId.value = value[0].id;
		},
		{immediate: true}
	);

	const rows = [
		{
			textTitle: "ADP Section A 1.1 - Primary & Community Care",
			textBody:
				"<p>Set out approach to extending and scale the multidisciplinary team preventative</p><p>approach to support strategic aims of both delivering more care in the community</p><p>and enhancing a focus on preventive care, with a view to testing the further</p><p>development of Community Treatment and Care Services (CTACs) over the</p><p>medium term.</p><p>Within your response, set out what you will deliver in terms of the scaling of the</p><p>MDT approach by quarter and set out expected impact in terms of increased</p><p>activity, extended hours. </p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisation's ability to promote digital collaboration. ",
			questions: [
				"PAjsNJxtXgjCjcvDMYxt",
				"V41fuQFDVoZUlBQ6vkvV",
				"4B8RN07gPQHNCOTGzSjx",
				"lMsBpDxNDQfIOypGfrjy",
				"ebWF0DExG4R77VqQKq2P"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 1.2 - Primary & Community Care",
			textBody:
				"<p>Boards to set out their plans to deliver a sustainable Out of Hours service,</p><p>utilising multi-disciplinary teams as referenced in the recommendations within the</p><p>Sir Lewis Ritchie Review</p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisation's ability to promote digital collaboration. ",
			questions: [
				"PAjsNJxtXgjCjcvDMYxt",
				"V41fuQFDVoZUlBQ6vkvV",
				"4B8RN07gPQHNCOTGzSjx",
				"lMsBpDxNDQfIOypGfrjy",
				"ebWF0DExG4R77VqQKq2P"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 1.3 - Primary & Community Care",
			textBody:
				"<p>Build and optimise existing primary care capacity to align with existing and</p><p>emerging mental health and wellbeing resources with primary care resource –</p><p>with the aim of providing early access to community-based services.</p>",
			textTip:
				"The questions shown here have been chosen because they show your organisation's capabilities when it comes to building a digitally enabled workforce and promoting digital collaboration. ",
			questions: [
				"jFQQWpka8SnQNBHSxQpB",
				"UAK6FrtkD5kpe3tWAfDw",
				"UP9BBRYDdLLj3QnpbCyV",
				"05Yw0V6MA3pxAbJCAvEc",
				"yrrnaemznRcHAAOq6ILX",
				"lMsBpDxNDQfIOypGfrjy",
				"ptJDQ4DuBnsxAoLlg4JC",
				"skzv8xvcD6nUI1eg5xqE"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 1.4 - Primary & Community Care",
			textBody:
				"<p>Analysis shows that the leading drivers of demand for urgent and unscheduled</p><p>care are respiratory disease and CVD (for which diabetes is a major risk factor)</p><p>and, for children, the way in which viruses are circulating in the population postpandemic.</p><p>In 2023/24, set out plans and approaches for the early detection and improved</p><p>management of the key cardiovascular risk factor conditions: diabetes, high</p><p>blood pressure and high cholesterol.</p>",
			textTip:
				"The questions shown here have been chosen because they show your organisation's digital capabilities underpinning preventative care efforts and risk stratification exercises. ",
			questions: [
				"9DidyRV543Xmw3suE4Jd",
				"2SXgYNtw7e3j5Z0F3jhv",
				"R6G9z6XhrxDDSSEbKt6K",
				"T4q4D5NwAslo1L9qw8OY",
				"hFo6a6tnmbMYLx7MaR9S",
				"4JII0B69oB1nfaJm5Y0v",
				"fUU21sX23CG6dy1KbtSS"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 1.5 - Primary & Community Care",
			textBody:
				"<p>Frailty</p><p>In parallel with the development of the national frailty programme, Boards are</p><p>asked to outline the approach of primary care to frailty and particularly managing</p><p>those at most risk of admission. This should include the approach to progressing</p><p>plans for Care Homes to have regular MDTs with appropriate professionals.</p>",
			textTip:
				"The question shown here have been chosen because it illustrates your organisation's ability to promote digital professional collaboration. ",
			questions: ["ebWF0DExG4R77VqQKq2P"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 1.6 - Primary & Community Care",
			textBody:
				"<p>Increase capacity for providing in-hours routine and urgent dental care for</p><p>unregistered and deregistered dental patients. Response should include</p><p>quarterly trajectories for at least 2023/24.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 1.7 - Primary & Community Care",
			textBody:
				"<p>As part of the objective of delivering more services within the community,</p><p>transition delivery of appropriate hospital-based eyecare into a primary care</p><p>setting, starting with the phased introduction of a national Community Glaucoma</p><p>Scheme Service.</p><p>Within your response, please include forecast 2023/24 eyecare activity that will</p><p>transition from hospital to primary care settings.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 1.8 - Primary & Community Care",
			textBody:
				"<p>Review the provision of IPC support available to Primary Care, including general</p><p>practice and dental practice, and consider how these settings can be supported</p><p>in the future, e.g., the use of peripatetic IPC practitioners.</p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate fundamental digital approaches that underpin IPC support. ",
			questions: [
				"Kttqg7P2KRzFcm0m9Mng",
				"L0wR0sEikB3A2Ce5KOMd",
				"xYaDZbkW6kquVKxAKesI",
				"rmNZWc5AdpiN7jWJcj5W",
				"w5xTVuXemP36DksWuS2s",
				"71INJAUGDag8g7jpRMFN",
				"crnFwFLN7JhygnBTXqqb"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 2.1 - Urgent & Unscheduled Care",
			textBody:
				"<p>Boards are asked to set out plans to progress from the De Minimis Flow</p><p>Navigation Centre (FNC) model to further optimise. Plans should include:</p><p>• Interface with NHS 24 in and out of hours</p><p>• Mental health pathways</p><p>• Development of new pathways for inclusion within FNC, including</p><p>consideration of paediatric pathways.</p><p>• Further reduce admissions by increasing professional to professional advice</p><p>and guidance via FNCs, including access for SAS (Call before you convey)</p><p>• Further develop public messaging (hard to reach communities)</p><p>• Further develop signposting alternative pathways, including paediatric.</p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate digital capabilities that may be fundamental to FNC operations generally, managing service user preferences, promote digital professional collaboration, or describe relevant outcomes from this action point (e.g., travel reduction / sustainability). ",
			questions: [
				"WRAlVaBrtFERQgTlI5nY",
				"oESPgocbJ4w3z3ioZ0FF",
				"USJWAbLl9morJGAuVV3q",
				"NoIRLn9Vapnmi3igvXrS",
				"kgPb1PTaZsJBysbMv8Sm",
				"NUYqIxetaLCztlLbzVax",
				"Nnx6mgjRdSfYgaNktuCq",
				"FI6kU1gYJjkymsZOdLn0",
				"qw9WvFlMSRMaon8UrmfO",
				"K7kVCPsgFa3huElI63Qi",
				"JOv8DI2ZExFT7AusuLkc",
				"V41fuQFDVoZUlBQ6vkvV",
				"4B8RN07gPQHNCOTGzSjx",
				"lMsBpDxNDQfIOypGfrjy",
				"ebWF0DExG4R77VqQKq2P",
				"MJJLYHLN527qdCuh0nvK",
				"D5zHtFaROrlavp3FFtj7",
				"EdU2YYTCCSUjB95Ldbtz",
				"KLBgBt5AX812UgEOtzgp",
				"p8BzLe4QQ9KySWAb4Qy5",
				"NTJhOzWitPBQ1ue7JuIQ"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 2.2 - Urgent & Unscheduled Care",
			textBody:
				"<p>Extend the ability to ‘schedule’ unscheduled care by booking patients into slots</p><p>which reduce self-presentation and prevent over-crowding.</p><p>Develop access to booked slots across wider urgent and emergency care system,</p><p>such as primary, secondary, community & mental health services and to include</p><p>children and babies.</p>",
			textTip:
				"The question shown here have been chosen because it illustrates your organisation's ability to promote digital appointment management. ",
			questions: ["TKRoVEDZ4nrVcebPvx2b"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 2.3 - Urgent & Unscheduled Care",
			textBody:
				"<p>Boards to outline plans for an integrated approach to all urgent care services</p><p>including Primary Care OOH and community services to optimise their assets.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 2.4 - Urgent & Unscheduled Care",
			textBody:
				"<p>Set out plans to implement and further develop OPAT, Respiratory and Hospital</p><p>at Home pathways.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 2.5 - Urgent & Unscheduled Care",
			textBody:
				"<p>Set out plans to introduce new pathways, including paediatrics and heart failure.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 2.6 - Urgent & Unscheduled Care",
			textBody:
				"<p>Boards are asked to set out plan to increase assessment capacity (and/or</p><p>footprint) to support early decision making and streaming to short stay pathways.</p><p>Response should include forecast reduction in length of stay through short stay</p><p>patients being admitted into short-stay wards, and reduction in Boarding levels. </p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 2.7 - Urgent & Unscheduled Care",
			textBody:
				"<p>Set out plans to deliver effective discharge planning seven days a week, through</p><p>adopting the ‘Discharge without Delay’ approach.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 2.8 - Urgent & Unscheduled Care",
			textBody:
				"<p>Best Start Maternity and Neonatal Plan: you should continue to move to full</p><p>delivery of The Best Start programme, as outlined in your Plan submitted to the</p><p>Best Start Programme Board in Autumn 2022.</p><p>Outline your approach to move towards full delivery of the Best Start Programme,</p><p>as outlined in your Plan submitted to the Best Start Programme Board in Autumn</p><p>2022. This should include summary of the delivery and assurance structures in</p><p>place including oversight at Board level</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 3.1 - Mental Health",
			textBody:
				"<p>Improving Access to Services</p><p>In 2023/24, all very long waits (over 52 weeks) to be addressed within CAMHS and</p><p>PT and demonstrable progress towards meeting and maintaining the 18 week waiting</p><p>times standard within both services.</p><p>Outline your plans to build capacity in services to eliminate very long waits (over 52</p><p>weeks) for CAMHS and PT and actions to meet and maintain the 18- week referral</p><p>to treatment waiting times standard. </p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate digital capabilities that may be fundamental to efficient service delivery and lower wait times.",
			questions: [
				"gLz9RtYaoOMoNvngEWiR",
				"qsH3R8vfD8Xh87xjm5j1",
				"5LIzxFPptCuq94r1nvIV",
				"bgbUQgzzESQJ7HIpG0xF",
				"LMDCwwEU2XnUK1RBN4D7"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 3.2 - Mental Health",
			textBody:
				"<p>To deliver services that meet standards</p><p>The Child and Adolescent Mental Health (CAMHS) and Neurodevelopmental</p><p>Specifications outlining provisions young people and their families can expect from the NHS were published in February 2020. </p><p>Outline your plans to build capacity in services to deliver improved services</p><p>underpinned by these agreed standards and specifications for service delivery</p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate digital support for requirements 1, 3, 4 and 5 of Standard 1 of the CAHMS and Neurodevelopmental Specification, requirements 2, 3, 4 and 7 of Standard 2 of the CAMHS and Neurodevelopmental Specifications, the informed consent element of requirement 8 of Standard 2 and requirement 3 of Standard 4 of the CAMHS and Neurodevelopmental Specification and requirement 7 of Standard 4 of the CAMHS and Neurodevelopmental Specification.",
			questions: [
				"PAjsNJxtXgjCjcvDMYxt",
				"UP9BBRYDdLLj3QnpbCyV",
				"05Yw0V6MA3pxAbJCAvEc",
				"oESPgocbJ4w3z3ioZ0FF",
				"USJWAbLl9morJGAuVV3q",
				"NoIRLn9Vapnmi3igvXrS",
				"NUYqIxetaLCztlLbzVax",
				"tKgZth4SQbNdXOmTPBBJ",
				"K7kVCPsgFa3huElI63Qi",
				"KiRq7MNDe4rQeP7dPnSr",
				"BIN43tfDSJZriGj3buqI",
				"hRttkOYPFTPOwo4ZVkzD",
				"2znSt02B9KJLHTuTtkmI",
				"KNnB7XAyFxvsxIBpm7aj",
				"MJJLYHLN527qdCuh0nvK",
				"QrOCwVLUb35vz71ncemC",
				"CoMROzaP1oGeQGh5F8LM",
				"EdU2YYTCCSUjB95Ldbtz",
				"skzv8xvcD6nUI1eg5xqE"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 3.3 - Mental Health",
			textBody:
				"<p>Data – engagement with PHS to improve quality of data</p><p>A core dataset – the CAHMS and Psychological Therapied National Dataset (CAPTND)</p><p>has been developed and PHS has been working with all NHS Boards to put in place a</p><p>robust collection to provide intelligence at an individual patient level. It was expected that the full core dataset would be routinely collected and reported by Boards by 2022</p><p>Boards should report on the timetable to achieve full compliance with CAPTND</p><p>data set and/or plans to improve quality as above which may include work to</p><p>replace or enhance their systems to achieve compliance. </p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate digital capabilities around data sharing, and moreover demonstrate digital support for the requirements of Standard 5 of the CAMHS and Neurodevelopmental Specifications",
			questions: ["2BRzBR8z4QGkTo01m4ru", "fUU21sX23CG6dy1KbtSS"],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 3.4 - Mental Health",
			textBody:
				"<p>Boards are asked to set out their plans to increase mental health services spend to</p><p>10% of NHS frontline spend by 2026 and plans to increase the spend on the</p><p>mental health of children and young people to 1%.</p><p>Boards are also asked to include within their return current percentage of total</p><p>frontline spend and the planned trajectory towards the 10% and 1% target.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 4.1 - Planned Care",
			textBody:
				"<p>Identifying a dedicated planned care bed footprint and associated resource by</p><p>Board/hospital to enable a “hospital within a hospital” approach in order to protect</p><p>the delivery of planned care.</p><p>CfSD are working with Boards that already have developed plans to target</p><p>increasing throughput in first instance.</p>",
			textTip:
				"The question shown here have been chosen because it illustrates your organisation's capabilities related to digital resource management. ",
			questions: ["f9ByxkWKU7Z3VX9v0vk6"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 4.2 - Planned Care",
			textBody:
				"<p>Extending the scope of day surgery and 23-hour surgery to increase activity and</p><p>maximise single procedure lists.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 4.3 - Planned Care",
			textBody:
				"<p>Set out the plan for 2023/24 to reduce unwarranted variation, utilising the Atlas</p><p>Maps of variation and working with CfSD and respective Specialty Delivery</p><p>Groups (SDGs) and Clinical Networks.</p><p>Responses should include forecast reductions across specialties and in theatre</p><p>productivity, day case activity or start and finish times. In addition, set out forecast</p><p>increase in activity for certain procedures to levels recommended by Royal</p><p>Colleges</p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisation's capabilities related to the digital demand forecasting. ",
			questions: ["rtSjjM3Yo9fk0Me9RKIt", "rVlNPMsseV2TlU0FQX2A"],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 4.4 - Planned Care",
			textBody:
				"<p>Approach to validation of waiting lists for patients waiting over 52 weeks, including</p><p>potential alternatives for treatment. Board responses should also outline level of</p><p>engagement with the National Elective Co-ordination Unit (NECU) to support</p><p>validation.</p>",
			textTip:
				"The question shown here has been chosen because it illustrates your organisation's capabilities related to digitally planning alternative care approaches. ",
			questions: ["JOv8DI2ZExFT7AusuLkc"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 5.1 - Cancer Care",
			textBody:
				"<p>Set out actions to expand diagnostic capacity and workforce, including</p><p>endoscopy and its new alternatives</p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisation's capabilities related to digital resource optimisation. ",
			questions: [
				"KQQTmi3UIlPHs7aV3E09",
				"VVIxPlbwe27eYfy8spN8",
				"IzDrIYaEB1QrC0xjsgnl",
				"tYjNAtXcyj6Q8rKE5H1k",
				"nTH8CB180ScSBQZySZY3",
				"7aV00cEwqLv4SCPqSgX2",
				"lOC8AZRYgz5ECNIPKKrA",
				"08eJsieO2iWGWp2k2wqa",
				"c6WlHDilMGOdnQOS6iaH",
				"YNwClBbi0eVoHVVz0mxr",
				"zFl1qVHxjT2fz3Vo2cky",
				"fu3GBmrWxr1sHnqnWZ7C",
				"hPsaoetKbgi0BuENU521"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 5.2 - Cancer Care",
			textBody:
				"<p>Plan for continued roll out of RCDS’s - both Board level and regional approaches</p><p>will be required.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 5.3 - Cancer Care",
			textBody:
				"<p>Set out plans to achieve full adoption of Framework for Effective Cancer</p><p>Management</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 5.4 - Cancer Care",
			textBody:
				"<p>Outline plans to improve the quality of cancer staging data</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 5.5 - Cancer Care",
			textBody:
				"<p>Confirm you have:</p><p>• Implemented or have plans to implement provision of single point of</p><p>contact services for cancer patients</p><p>• Embed referral, where clinically appropriate, to Maggie’s prehab service</p><p>and use of national prehab website in cancer pathways</p><p>• Assurance of routine adherence to optimal diagnostic pathways and</p><p>Scottish Cancer Network clinical management pathways</p><p>• Embed the Psychological Therapies and Support Framework</p><p>• Signposting and referral to third sector cancer services embedded in all</p><p>cancer pathways</p><p>In addition, Boards are asked to confirm that they will engage and support with</p><p>future data requests and advice to deliver the upcoming National Oncology</p><p>Transformation Programme.</p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisation's capabilities relevant to the signposting aspects of this requirement. ",
			questions: [
				"MJJLYHLN527qdCuh0nvK",
				"D5zHtFaROrlavp3FFtj7",
				"EdU2YYTCCSUjB95Ldbtz",
				"KLBgBt5AX812UgEOtzgp"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 6.1 - Health Inequalities",
			textBody:
				"<p>Summarise local priorities for reducing health inequalities taking into account</p><p>national strategies around Race, Women's Health Plan and any related actions</p><p>within most recent Equality Mainstreaming Report</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 6.2 - Health Inequalities",
			textBody:
				"<p>Set out actions to strengthen the delivery of healthcare in police custody and</p><p>prison; ensuring improvement in continuity of care when people are transferred</p><p>into prison and from prison into the community. Boards are also asked to set out</p><p>any associated challenges in delivering on the actions.</p><p>This should include actions to allow primary care staff to have access to prisoner</p><p>healthcare records and delivery against MAT Standards.</p><p>Boards are also asked to state their Executive Lead for prisons healthcare and</p><p>those in custody, reflecting that the prisoner population is spread across all Board</p><p>areas</p>",
			textTip:
				"The question shown here has been chosen because it underpins digitally identifying local health inequality priorities.",
			questions: ["hFo6a6tnmbMYLx7MaR9S"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 6.3 - Health Inequalities",
			textBody:
				"<p>Set out plan to deliver the National Mission on Drugs specifically the</p><p>implementation of MAT Standards, delivery of the treatment target and increasing</p><p>access to residential rehabilitation. </p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 6.4 - Health Inequalities",
			textBody:
				"<p>Establish a Women’s Health Lead in every Board to drive change, share best</p><p>practice and innovation, and delivery of the actions in the Women’s Health Plan</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 6.5 - Health Inequalities",
			textBody:
				"<p>Set out approach to developing an Anchors strategic plan by October 2023 which</p><p>sets out governance and partnership arrangements to progress anchor activity;</p><p>current and planned anchor activity and a clear baseline in relation to workforce;</p><p>local procurement; and use or disposal of land and assets for the benefit of the</p><p>community.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 6.6 - Health Inequalities",
			textBody:
				"<p>Accessibility to services is as an integral part of healthcare, and NHS Boards</p><p>should give consideration to transport needs in the planning and delivery of</p><p>services. This should include consideration of how best to work with Regional</p><p>Transport Partnerships (RTPs) and transport officers from local authorities.</p><p>Outline how the Board will ensure Patients have access to all information on any</p><p>relevant patient transport (including community transport) and travel</p><p>reimbursement entitlement.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 7.1 - Innovation Adoption",
			textBody:
				"<p>Boards to set out the approach and plans to work with ANIA partners (coordinated by</p><p>CfSD) to adopt and scale all approved innovations coming through the ANIA pipeline.</p><p>This should include an outline of Board resource to support the associated business</p><p>change to realise the benefits, which could include collaborative approaches to</p><p>adoption. </p>",
			textTip:
				"The questions shown here have been chosen because they would be directly relevant to digital projects emanating from the ANIA pipeline. ",
			questions: [
				"YvYWcV2aLfIzUOmDkmI6",
				"DyK5UAS5M285pJ7o9mqs",
				"mAiXoGIsu9gBn1ekqKSr",
				"e7kcWSV7pRXZ4VrWoF07",
				"CX24fOjLaLUMWICFxPTc",
				"j2MxxK3QdYjEMi46LSEv",
				"uIA0UTMMKPssow8egkJZ",
				"S8zgTAinEJXSiu8H8POJ"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 7.2 - Innovation Adoption",
			textBody:
				"<p>Work in collaboration with a range of national organisations to combine the right skills</p><p>and capabilities across Scotland to reduce the barriers to national innovation adoption</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 8.1 - Workforce",
			textBody:
				"<p>Support all patient-facing Boards to implement the delivery of eRostering across all</p><p>workforce groups</p><p>Resources to be identified locally to support business change and roll out of eRostering/safer staffing too including optimal integration between substantive and flexible staff resource</p>",
			textTip:
				"The questions shown here have been chosen because they illustrate your organisations capabilities related to digital HR management. ",
			questions: ["7dShl4VEHYNx5hGIZ2Zo", "n3akeAnGPCAwwcvyUvV5"],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 9.1 - Digital",
			textBody:
				"<p>Optimising M365</p><p>Boards to set out plans to maximise use and increase benefits of the Microsoft 365</p><p>product. Plans should consider collaborative (local/regional/national) to offer</p><p>alternative options for the delivery of programme benefits. This should include:</p><p>• Outlining what resources and approaches are being used to roll out M365</p><p>collaboration across Health and Care Integrated Authorit(y)ies.</p><p>• Describing the approaches being taken to deliver business change and realise the</p><p>benefits of the M365 product</p><p>• Confirming which current tools are being used, how they are being utilised and</p><p>plans for future role out of applications including (but not limited to) Sharepoint,</p><p>automation and retirement of legacy applications</p><p>• Describing how M365 data and licences are being used and controlled locally</p><p>• Outlining the approach being taken and confirmation of compliance with</p><p>Information Security, Information Governance and Data Protection standards</p><p>• Providing milestones for the deployment of document management classification</p><p>scheme working practices compliant with GDPR guidance will be fully embedded</p><p>and operational</p><p>• Outlining how you will develop and improve digital skills of the workforce to realise</p><p>the full operational benefits of M365 </p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 9.2 - Digital",
			textBody:
				"<p>National digital programmes</p><p>Boards to provide high level plans for the adoption/implementation of the national</p><p>digital programmes*. This should include:</p><p>• Position Statement – including work undertaken to date and areas outstanding</p><p>• Highlighting any issues/challenges with adoption/implementation and what plans</p><p>are in place to mitigate any issues should they arise</p><p>• High level milestones in 23/24</p><p>• An outline of the resources identified to support business change for national</p><p>programmes</p><p>Health Boards to provide an update on new initiatives/developments to embrace the</p><p>use of local systems to support the DHAC delivery plan and the implementation of an</p><p>integrated care record. For example, use of Health Share, developments to Trakcare,</p><p>Care Portal.</p><p>Boards are encouraged to identify areas of best practice or opportunities' that could</p><p>be shared across NHS Scotland.</p><p>*National digital programmes: CHI, Child Health, GP IT, eRostering, LIMS,</p><p>HEPMA, M365, endoscopy reporting system, Diagnostics (PACs), Near Me, Connect</p><p>Me, Scottish Vaccination Immunisation Programme (SVIP)</p>",
			textTip:
				"The questions shown here have been chosen because they are be  relevant to adoption of CHI and your organisation's adoption of national systems. ",
			questions: [
				"2dfcQBILFra06350O1A2",
				"p8BzLe4QQ9KySWAb4Qy5",
				"UYd2YfHe1LDvv9UUzPvf",
				"KG8LIZ5sFpqNL2lmK7kN",
				"If8w9hLt2xZuaMRAORXJ",
				"vgCBtDaLu1Mvt3rsgWXo",
				"8kNR9T7UUbIhalb8faav",
				"wxROVYTKgNitk3uZThAb",
				"2KPnRfjg8FOZ45l8Yd7t",
				"qTX6JLR0LK0jzeIbjIlK",
				"dz2c9S87zL0gsdiBf5Ta",
				"2DId7793eJpd2D8oFDJL",
				"1vlBzVn5K9JKPKhmqYVv",
				"vdqxJSrJf3z15CYGqAex",
				"xXpFcsrOHwMzdJIiPaU3",
				"Hr2OGCjZTiOKZESXTvMo",
				"4KWdAdHvnBa20ym4jTAd",
				"Jw1Q35A2HRkSlfmNfpqZ",
				"bu694IeEPgeexPYC0t1i",
				"6d0066FSxTWvqAyO0d4n",
				"nxSykd3yYxdn6XmtSiIF",
				"sSgOvbPvLpfARpY55urE"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 9.3 - Digital",
			textBody:
				"<p>Boards to complete the Organisational Digital Maturity Exercise to be issued in</p><p>April 2023, as fully as possible and in collaboration with their respective Integrated</p><p>Authorit(y)ies.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 9.4 - Digital",
			textBody:
				"<p>Leadership in digital</p><p>Boards should outline:</p><p>• Executive support and commitment to how you are optimising use of digital &</p><p>data technologies in the delivery of health services and ongoing commitment to</p><p>developing and maintaining digital skills across the whole workforce</p><p>• How candidates accepted on to the Digital Health and Care Transformational</p><p>Leaders master's Programme are being supported and how learning is being</p><p>shared across the organisation</p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate executive commitment to optimising the use of digital generally and the development of a digitally enabled workforce. ",
			questions: [
				"q893H6s0zJOI2PVdpmx9",
				"j9jvUM5PcaDmPkWgeiEo",
				"KngbSfEFIbAOcWYcLXsf",
				"RNxpxkRYYNkHKgnUIHnk",
				"df0ZikfgniL0Ocgdzy0B",
				"9RjLNeUqLZBBXeY1uswK",
				"TDAWvLb0jAFdMJAu02d2",
				"tvBEHAfYNpsCvEj7UMI5",
				"IfugDg0zhCBkl6Y3aGn6",
				"pFlDH6GXrE3lPUrizlxM",
				"kmSYZgdVW4dz6Igv0H0t",
				"BlsdJRBjZkT0P2AIU1Ed",
				"XJ5DhTcCHfZImx5wCfoM",
				"YvYWcV2aLfIzUOmDkmI6",
				"hEs8DBkrDVNhErbvPgRs",
				"eM6GrHGAY7yrTwQc47B2",
				"aDOWeO4cV5LgDwEwzTyK",
				"rJw1WXlOFCuMpvVW0TsN",
				"tdf1eQeJB8TcYDKFCvQv",
				"ZhCbgMdoaFBUWD9X6AvE",
				"NlilelLJoLd4EkG3Aqfg",
				"7YH5nLmDIBw46jS0naRP",
				"jFQQWpka8SnQNBHSxQpB",
				"UAK6FrtkD5kpe3tWAfDw",
				"UP9BBRYDdLLj3QnpbCyV",
				"05Yw0V6MA3pxAbJCAvEc",
				"yrrnaemznRcHAAOq6ILX",
				"8da3aWCm6XBIpYqSa4qG",
				"jOieINLQPaikE4kYhU6T",
				"rik4VXhqxvbCnFgWoYuR",
				"uxG5ZjpcEB4f2POqNbZ5",
				"xQVVLAjPL4nHjHBBdL3q",
				"5ggTinOknXqov0MN7N5V"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 9.5 - Digital",
			textBody:
				"<p>Scottish Health Competent Authority /Network & Information Systems</p><p>Regulations (NI)s Regulation Audits</p><p>Boards to demonstrate progress against the level of compliance with the Refreshed</p><p>Public Sector Cyber Resilience Framework via the independent audit process. Health</p><p>boards must follow the 2023 audit programme guidance and adopt the new evidence</p><p>template.</p><p>Health Boards should outline processes in place for engaging with the Cyber Centre</p><p>of Excellence (CCoE) as part of compliance with the NIS regulations.</p>",
			textTip:
				"The questions shown here have been chosen because they demonstrate compliance with requirements under domains 1, 2, 3 and 4 of the Refreshed Public Sector Cyber Resilience Framework ",
			questions: [
				"PIBKaIjPV7oYmGwMQ1Z2",
				"6RodJcg8j0YLbEP6uGyK",
				"JAB2nul5y2rJK4zCKBxl",
				"NEVyd3VIunNYg39PlI2v",
				"b5DaVNnHAKFlossSWA3m",
				"ngQUhTNRy4oCgDtRL0za",
				"4xDap5LHvLaL6CwlJL0y",
				"frx2ptx0kc7MwWDEWOi0",
				"sWK2AvJnF0sw8z9XuBUA",
				"rWEohiKBggcofPTCaZ0a",
				"wul3PMIl48oviMp4fWnY",
				"QjzZLUNf0dmGU8TbrapB",
				"dNfyZ4RCuinOgtLX6Vi1",
				"yeh3Uf4nuJcvp5tmMzmd",
				"Bbo00OJw92kv5owzqtV9",
				"SIlkw11iV37jsCAIY1J3",
				"m8k45ghYl8MKjrnf0heK",
				"CW5dkl0quoaMS80ko9Ki",
				"cNXjeU5NJBvmevRL6eKd",
				"mLsFytVgtVL6O4VbhcYF",
				"2YierYVCpNggBNLtS5N8",
				"z5LM16z19gPGivnOx2jj",
				"odPjuF25QK6yY85EdMO9",
				"TduaegB9sv5GNNAr1Jch"
			],
			vizType: "chart"
		},
		{
			textTitle: "ADP Section A 10.1 - Climate",
			textBody:
				"<p>Set out proposed action to decarbonise fleet in line with targets (2025 for cars / light</p><p>commercial vehicles & 2032 for heavy vehicles at latest).</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 10.2 - Climate",
			textBody:
				"<p>Set out plan to achieve waste targets set out in DL (2021) 38.</p>",
			textTip:
				"The question shown here has been chosen because it underpins digital, efficient waste management and green target management",
			questions: ["QjyU6VFj9zd5c8kaWO2V"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 10.3 - Climate",
			textBody:
				"<p>Set out plan to reduce medical gas emissions – N20, Entonox and volatile gases –</p><p>through implementation of national guidance</p>",
			textTip:
				"The question shown here has been chosen because it underpins digital, efficient waste management and green target management",
			questions: ["QjyU6VFj9zd5c8kaWO2V"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 10.4 - Climate",
			textBody:
				"<p>Set out actions to adopt the learning from the National Green Theatre Programme;</p><p>provide outline for greater adoption level.</p>",
			textTip:
				"The question shown here has been chosen because it underpins digital, efficient waste management and green target management",
			questions: ["QjyU6VFj9zd5c8kaWO2V"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 10.5 - Climate",
			textBody:
				"<p>Set out approach to develop and begin implementation of a building energy</p><p>transition programme to deliver energy efficiency improvements, increase on-site</p><p>generation of renewable electricity and decarbonise heat sources.</p>",
			textTip:
				"The question shown here has been chosen because it underpins digital, efficient management of energy transition",
			questions: ["4iUVIlBb94yzX4jFmmzj"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section A 10.6 - Climate",
			textBody:
				"<p>Set out approach to implement the Scottish Quality Respiratory Prescribing guide</p><p>across primary care and respiratory specialities to improve patient outcomes and</p><p>reduce emissions from inhaler propellant.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section A 10.7 - Climate",
			textBody:
				"<p>Outline plans to implement an approved Environmental Management System.</p>",
			textTip:
				"The question shown here has been chosen because it underpins the use of digital management systems. ",
			questions: ["WGgrd6Wx1MGdcVKVKm3O"],
			vizType: "number"
		},
		{
			textTitle: "ADP Section B B - Finance and Sustainability",
			textBody:
				"<p>Identify any risks and issues to delivery of the ADP, with reference to the need for financial balance and associated improvements through, for example, Sustainability and Value Programme.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section C C - Workforce",
			textBody:
				"<p>Please include an update on the implementation of Board workforce plans.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section D D - Value Based Health and Care",
			textBody:
				"<p>Please outline work underway with your local Realistic Medicine Clinical Lead to deliver local RM Plans.  </p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section E E - Integration",
			textBody:
				"<p>Please demonstrate how the ADP has been developed with partner Integration Authorities.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section F F - Improvement Programmes",
			textBody:
				"<p> Please summarise improvement programmes that are underway, along with the expected impact and benefits of this activity.</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		},
		{
			textTitle: "ADP Section G G - Service Sustainability",
			textBody:
				"<p>We are asking Boards to complete an initial assessment of services with sustainability / resilience issues, updating information provided in March 2022. This will begin to inform the shape of national, regional and local service planning and delivery, feeding into the proposed SLWG. Further guidance on this will be provided at information sessions planned with Directors of Planning over March & April</p>",
			textTip: "No assessment questions linked to this topic. ",
			questions: null,
			vizType: null
		}
	];
	onMounted(() => {
		dashboard.start();
	});
	watch(organisationId, (value) => {
		if (value) {
			organisationsStore.includeOrganisation(organisationsStore.keyed[value]);
		}
	});
</script>
