import {defineStore} from "pinia";
import {ref, computed} from "vue";

export const useAuthStore = defineStore("auth", () => {
	const collectionName = "users";
	const type = "doc";
	let id = ref(null);
	let email = ref(null);
	let phone = ref(null);
	let name = ref(null);
	let last_login = ref(null);
	let created_at = ref(null);
	let role = ref("user");
	let organisations = ref([]);
	let registration_type = ref("user");
	let created_by = ref(null);
	let permissions = ref({});
	let status = ref(null);
	let regions = ref([]);
	let data_views = ref([]);
	let approver = ref(null);
	let updated_at = ref(null);
	let registration_method = ref(null);
	let organisation_stakeholder = ref(null);
	let updated_by = ref(null);
	let isLoggedIn = computed(() => {
		return id.value ? true : false;
	});
	
	// const isAdmin = () => {
	// 	return ["admin", "superadmin"].includes(role.value);
	// };
	const canViewData = () => {
		return (
			permissions.value.view_own_data || permissions.value.view_average_data
		);
	};

	const canViewRegionalData = () => {
		return permissions.value.view_regional_data;
	};

	let accessDenied = computed(() => {
		if (!id.value) {
			return null;
		}
		return !canViewData();
	});
		
	
	return {
		id,
		options: {
			collectionName,
			type
		},
		name,
		email,
		phone,
		last_login,
		created_at,
		role,
		organisations,
		registration_type,
		created_by,
		status,
		permissions,
		approver,
		updated_at,
		registration_method,
		organisation_stakeholder,
		updated_by,
		isLoggedIn,
		accessDenied,
		regions,
		canViewRegionalData,
		data_views
	};
});
