<template>
	<v-sheet>
		<h1 class="font-weight-light">
			Scottish Government/COSLA Digital Maturity Assessment Data
		</h1>
		<v-card class="mb-5 mt-6" color="lightest" flat>
			<v-card-text>
				<p>
					This interactive dashboard provides access to data collected as part
					of the Digital Maturity Assessment, including (where available)
				</p>
				<ul class="ml-5">
					<li>
						2023 assessment data for your organisation and your local partner
						organisations (scores and responses)
					</li>
					<li>
						2019 comparison data for mapped questions (scores and responses)
					</li>
					<li>The 2023 national average</li>
				</ul>
				<p>
					Data can be viewed in table format or as a visualisation (note that
					only numeric data can be visualised). You can switch between those
					views using the tabs below.
				</p>
				<p>
					You can select data you want to view using the controls on the right.
					For more information, please visit the tutorials page.
				</p>
			</v-card-text>
		</v-card>
		<v-card>
			<v-tabs v-model="dashboard.tab" bg-color="primary">
				<v-tab value="table">Table View</v-tab>
				<v-tab value="viz">Visualisation</v-tab>
			</v-tabs>
			<v-card-text>
				<v-window v-model="dashboard.tab">
					<v-window-item value="table">
						<main-table></main-table>
					</v-window-item>
					<v-window-item value="viz">
						<main-viz></main-viz>
					</v-window-item>
				</v-window>
			</v-card-text>
		</v-card>
		<dashboard-sidebar></dashboard-sidebar>
		<loading-spinner v-model="loading"></loading-spinner>
	</v-sheet>
</template>

<script setup>
	import {computed, onMounted} from "vue";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import MainTable from "@/components/dashboard/MainTable";
	import MainViz from "@/components/dashboard/MainViz";
	import DashboardSidebar from "@/components/dashboard/DashboardSidebar";
	import LoadingSpinner from "@/components/ui/LoadingSpinner";
	const dashboard = useDashboardStore();
	const loading = computed(() => dashboard.loading);
	onMounted(() => {
		dashboard.start();
	});
</script>
