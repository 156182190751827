<template>
	<h1 class="font-weight-light">
		Scottish Government/COSLA Digital Maturity Assessment Data
	</h1>
	<v-card class="mb-5 mt-6" color="lightest" flat>
		<v-card-text>
			<p>
				From September 2023, organisations participating in the Scottish
				Government/COSLA Digital Maturity Assessment can update their
				assessments at any time (rather than as part of a national data
				collection drive).
			</p>
			<p>
				The visualisation below illustrates the development of an organisation's
				digital maturity by showing assessment results as a time series spanning
				any assessments submitted.
			</p>
			<p>
				Customise the data shown by filtering what data is included using the
				buttons on the right! You can view data by theme, section and service,
				include comparisons with the national average, and define what
				assessments to include.
			</p>
		</v-card-text>
	</v-card>
</template>
