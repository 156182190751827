<template>
	<v-navigation-drawer touchless permanent v-model="drawer" :rail="rail">
		<v-list>
			<welcome-message></welcome-message>
		</v-list>
		<v-list dense>
			<slot></slot>
			<template v-for="(item, i) in items" :key="i">
				<v-list-item router-link :to="item.link">
					<template v-slot:prepend>
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</template>
					<v-list-item-title>
						{{ item.title }}
					</v-list-item-title>
				</v-list-item>
			</template>
			<template v-if="auth.id">
				<template v-for="(item, i) in logged_in_items" :key="i">
					<v-list-item router-link :to="item.link">
						<template v-slot:prepend>
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</template>
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
					</v-list-item>
				</template>
			</template>
			<help-navigation></help-navigation>
		</v-list>
		<template v-slot:append>
			<v-list>
				<log-out v-if="auth.id"></log-out>
				<chat-button></chat-button>
				<template v-for="(item, i) in append" :key="i">
					<v-list-item router-link :to="item.link">
						<v-list-item-title>
							{{ item.title }}
						</v-list-item-title>
						<template v-slot:prepend>
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</template>
					</v-list-item>
				</template>
				<v-list-item title="Collapse Menu" @click="rail = !rail">
					<template v-slot:prepend>
						<v-icon>mdi-menu</v-icon>
					</template>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script setup>
	import {ref, computed} from "vue";
	import {useAuthStore} from "@/stores/modules/auth";

	import LogOut from "@c/auth/LogOut";
	import WelcomeMessage from "@c/auth/WelcomeMessage";
	import ChatButton from "@c/navigation/ChatButton";
	import HelpNavigation from "@c/navigation/HelpNavigation";
	import {useOrganisationsStore} from "@/stores/modules/organisations";
	const auth = useAuthStore();
	const organisationsStore = useOrganisationsStore();
	let rail = ref(false);
	let drawer = ref(true);

	let items = [];

	let logged_in_items = computed(() => {
		const links = [
			{link: "dashboard", title: "Data Dashboard", icon: "mdi-poll"},
			{link: "time-series", title: "Time Series", icon: "mdi-clock-start"}
		];
		let match = auth.organisations.find((id) => {
			let organisation = organisationsStore.keyed[id];
			if (!organisation) {
				return;
			}
			return (
				organisation.provider && organisation.provider == "9NScqyIrxc3o0IfNebQn"
			);
		});
		if (match) {
			links.push({
				link: "delivery-plan",
				title: "Delivery Plan",
				icon: "mdi-sign-direction"
			});
		}
		return links;
	});
	let append = [{link: "contact", title: "Contact Us", icon: "mdi-at"}];
</script>
