<template>
	<Pie ref="chart" :id="id" :options="chartOptions" :data="chartData" />
</template>

<script setup>
	import {Pie} from "vue-chartjs";
	import {
		Chart as ChartJS,
		ArcElement,
		Tooltip,
		Legend,
		Colors
	} from "chart.js";

	import {defineProps, computed} from "vue";
	import _ from "lodash";

	ChartJS.register(ArcElement, Tooltip, Legend, Colors);

	const props = defineProps({
		data: Object,
		options: Object,
		id: String,
		title: String
	});
	const defaultOptions = {
		responsive: true
	};
	const chartData = computed(() => {
		return props.data;
	});
	const chartOptions = computed(() => {
		let options = {...defaultOptions, ...props.options};
		_.set(options, "plugins.title", {display: true, text: props.title});
		return options;
	});
</script>
