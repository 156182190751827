<template>
	<Bar ref="chart" :id="id" :options="chartOptions" :data="chartData" />
</template>

<script setup>
	import {Bar} from "vue-chartjs";
	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		PointElement,
		LineElement,
		BarElement,
		CategoryScale,
		LinearScale,
		Colors,
		TimeScale
	} from "chart.js";
	import {defineProps, computed} from "vue";
	import _ from "lodash";

	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		BarElement,
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Colors,
		TimeScale
	);

	const props = defineProps({
		data: Object,
		options: Object,
		id: String,
		title: String
	});
	const defaultOptions = {
		responsive: true
	};
	const chartData = computed(() => {
		return props.data;
	});
	const chartOptions = computed(() => {
		let options = {...defaultOptions, ...props.options};
		_.set(options, "plugins.title", {display: true, text: props.title});
		return options;
	});
</script>
