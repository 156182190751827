<template>
	<v-btn block variant="text" dark @click="dl">Download PPT</v-btn>
</template>

<script setup>
	import PptxGenJS from "pptxgenjs";
	import {computed, defineProps} from "vue";
	import chartUtils from "@/assets/chartUtils";
	import {useDashboardStore} from "@/stores/modules/dashboard";

	const dashboard = useDashboardStore();

	const props = defineProps({id: String});
	const title = computed(() => dashboard.title);
	const makeImage = () => {
		const canvas = document.getElementById(props.id);
		if (!canvas) {
			//
		} else {
			return canvas.toDataURL("image/png");
		}
	};

	const getImageHeight = (displayWidth) => {
		const el = document.getElementById(props.id);
		let w = el.width;
		let h = el.height;
		let displayHeight = displayWidth * (h / w);
		return displayHeight;
	};
	const tableRows = computed(() => {
		let rows = [];

		rows.push(
			[{text: "Series"},
			...dashboard.chartData.labels.map((label) => {
				return {text: label, options: {bold: true}};
			})]
		);

		dashboard.chartData.datasets.forEach((dataset) => {
			rows.push([dataset.label, ...dataset.data]);
		});

		return rows;
	});

	const dl = () => {
		let pptx = new PptxGenJS();
		pptx.layout = "LAYOUT_WIDE";

		pptx.defineSlideMaster(chartUtils.masterSlide);

		let slide = pptx.addSlide({masterName: "MASTER_SLIDE"});
		slide.addText(title.value, {
			x: 0.5,
			y: 0.7,
			fontSize: 18
		});
		slide.addText("Chart prepared by Meisterworks", {
			y: 0.9,
			x: 0.5,
			fontSize: 12,
			hyperlink: {url: "https://m.works", tooltip: "Visit Meisterworks"}
		});
		slide.addImage({
			data: makeImage(),
			w: 6,
			h: getImageHeight(6),
			x: 0.5,
			y: 1
		});

		slide.addTable(tableRows.value, {w: 6, x: 7, y: 1.7});

		pptx.writeFile({fileName: "dma.ppt"});
	};
</script>
