<template>
	<v-tooltip location="top" text="Pivot chart">
		<template v-slot:activator="{props}">
			<v-btn v-bind="props" icon color="success" @click="toggle">
				<v-icon>mdi-rotate-right-variant</v-icon>
			</v-btn>
		</template>
	</v-tooltip>
</template>

<script setup>
	import {defineProps, defineEmits} from "vue";

	const props = defineProps({
		countBy: String,
		groupBy: String
	});
	const $emits = defineEmits(["update:countBy", "update:groupBy"]);

	const toggle = () => {
		$emits("update:countBy", props.groupBy);
		$emits("update:groupBy", props.countBy);
	};
</script>
