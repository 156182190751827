import {
	onSnapshot,
} from "firebase/firestore";

import {getDocRef} from "../../../mwFirebase";


export default ({store}) => {
	store.watchDoc = async (id) => {
		if( !id ){
			id = store.id;
		}
		store.ref = getDocRef(store.options.collectionName, id);
		const unsubscribe = onSnapshot(store.ref, (doc) => {
			if (doc.exists()) {
				store.$patch(doc.data());
			} else {
				if (store.handleEmptyDoc) {
					store.handleEmptyDoc();
				}
			}
		});
		store.mwUnsubscribe = unsubscribe;
		return id;
	};
	store.unwatchDoc = () => {
		store.mwUnsubscribe();
		store.$reset();
	};
};

