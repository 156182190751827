<template>
	<span>
		<v-list-item title="Live Chat" @click="toggleChat">
			<template v-slot:prepend>
				<v-icon>mdi-chat</v-icon>
			</template>
		</v-list-item>

		<div id="mwChatWrapper"></div>
	</span>
</template>

<style lang="less">
	#intergramRoot {
		div {
			right: auto !important;
			right: 70px !important;
			box-shadow: none !important;
			div {
				font-weight: lighter !important;
				font-size: 18px;
				// visibility: hidden;
				svg {
					// visibility: hidden;
				}
			}
		}
	}
</style>

<script setup>
	import _ from "lodash";
	import {computed, watch, ref, onMounted} from "vue";
	import {useAuthStore} from "@/stores/modules/auth";
	const auth = useAuthStore();

	let added = ref(false);
	let opened = ref(false);

	const email = computed( () => auth.email);

	let intergramCustomizations = computed(() => {
		return {
			titleOpen: "Chat",
			titleClosed: "Live chat",
			introMessage: "Hello. How can I help you?",
			autoResponse:
				"Thank you for your message. An operator will respond shortly",
			autoNoResponse: "Sorry it's taking us longer than usual to respond. ",
			mainColor: "#1478C6",
			alwaysUseFloatingButton: false
		};
	});

	onMounted(( ) => {
		window.intergramOnOpen.visitorName = "Reults visitor";

	})
	watch(
		email,
		(v) => {
			_.set(window, "intergramOnOpen.visitorName", `(Results) ${v}` )
		},
		{immediate: true}
	);

	const toggleChat = () => {
		if (!added.value) {
			startChat();
		} else {
			openChat();
		}
	};

	const startChat = () => {
		var chatScript = document.createElement("script");
		chatScript.setAttribute("src", "https://www.intergram.xyz/js/widget.js");
		chatScript.setAttribute("id", "intergram");
		window.intergramId = "-437300287";
		window.intergramCustomizations = intergramCustomizations.value;
		document.body.appendChild(chatScript);
		setTimeout(function () {
			dispatchEvent(new Event("load"));
			added.value = true;
			openChat();
		}, 200);
	};
	const openChat = () => {
		let intergramRoot = document.getElementById("intergramRoot");
		if (intergramRoot) {
			let container = intergramRoot.querySelector("div");
			let button = container.querySelector("div");
			button.click();
			opened.value = true;
		} else {
			startChat();
		}
	};
</script>
