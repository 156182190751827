<template>
	<p class="mt-2">
		<v-btn
			size="small"
			v-if="outstanding.length"
			color="success"
			dark
			block
            class="rounded-xl"
			@click="update">
			<v-icon class="mr-3">mdi-plus-circle</v-icon>
			Add {{ outstanding.length }} to selection
		</v-btn>
		<v-btn 
			size="small"
			v-if="selection.length"
            class="rounded-xl mt-1"
			color="red"
			dark
			block
			@click="clear">
			<v-icon class="mr-3">mdi-close-circle</v-icon>
			Clear {{ selection.length }} from selection
		</v-btn>
	</p>
</template>

<script setup>
	import {computed} from "vue";

	import {useDashboardStore} from "@/stores/modules/dashboard";

	const dashboard = useDashboardStore();
	const selected = computed(() => dashboard.selected);
	const selection = computed(() => dashboard.selection);
	const outstanding = computed(() => {
		const ids = selection.value.map((f) => f._id);
		return selected.value.filter((row) => !ids.includes(row.id));
	});

	const update = () => {
		outstanding.value.forEach((row) => dashboard.selection.push(row));
        dashboard.selected = [];
	};

	const clear = () => {
		dashboard.selection = [];
	};
</script>
