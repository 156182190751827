<template>
	<v-sheet flat>
		<bar-chart
			title="Scottish Government/COSLA Digital Maturity Assessment Data"
			id="timeSeriesViz"
			:data="{datasets: timeSeries.datasets}"
			:options="timeSeries.options">
		</bar-chart>
	</v-sheet>
</template>

<script setup>
	import {useTimeSeriesStore} from "@/stores/modules/timeSeries";
	import BarChart from "@c/charts/BarChart.vue";

	const timeSeries = useTimeSeriesStore();
</script>
