import {defineStore} from "pinia";
import {ref} from "vue";

export const useAnswersStore = defineStore("answers", () => {
	// settings for the collection plugin
	const type = "collection";
    const collection = ref([
        {name: "Not Applicable", id: 'na'},
        {name: "Agree Completely", id: 'agree-completely'},
        {name: "Somewhat Agree", id: 'somewhat-agree'},
        {name: "Neither Agree nor Disagree", id: 'neither-agree-nor-disagree'},
        {name: "Somewhat Disagree", id: 'somewhat-disagree'},
        {name: "Disagree Completely", id: 'disagree-completely'},        
        {name: "A national solution", id: "a-national-solution"},
        {name: "A national solution alongside other types of solutions", id: "a-national-solution-alongside-other-types-of-solutions"},
        {name: "A single departmental solution", id: "a-single-departmental-solution"},
        {name: "A single integrated solution / single hospital system component", id: "a-single-integrated-solution"},
        {name: "Don't Know", id: "don-t-know"},
        {name: "No solution", id: "no-solution"},
        {name: "One or more locally developed solutions", id: "one-or-more-locally-developed-solutions"},
        {name: "Several departmental solutions", id: "several-departmental-solutions"},
        {name: "Calendar", id:"calendar"},
        {name: "Don't Know", id:"don-tknow"},
        {name: "Email", id:"email"},
        {name: "None of the above", id:"none-of-the-above"},
        {name: "Office 365", id:"office-365"},
    ])
	return {
        collection,
		options: {
            collectionName: "questionTemplates",
			type,
			loadAll: false
		}, 
	};
});
