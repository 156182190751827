<template>
	<v-list-group value="help">
		<template v-slot:activator="{props}">
			<v-list-item
				prepend-icon="mdi-help-circle"
				title="Help"
				v-bind="props"></v-list-item>
		</template>
		<v-list-item
			v-for="({title, icon, to}, i) in links"
			:key="i"
			:title="title"
            :to="to"
			:prepend-icon="icon"
			:value="title"></v-list-item>
	</v-list-group>
</template>

<script setup>
    const links = [
        {title: "Tutorials", icon: 'mdi-information', to: "/tutorials"},
        {title: "Privacy", icon: 'mdi-lock-check', to: "/privacy"},
        {title: "Support", icon: 'mdi-face-agent', to: "/support"}
    ]
</script>
