<template>
    <v-btn  block variant="text" dark @click="dl">Download Image</v-btn>
</template>

<script setup>
    import {defineProps} from "vue";
    const props = defineProps({"id": String});
	import {useToastStore} from "@/stores/modules/toast";

	const dl = () => {
		const canvas = document.getElementById(props.id)
		if (!canvas) {
			let toasts = useToastStore();
			toasts.show("Error downloading file", true);
		} else {
			var link = document.createElement("a");
			link.download = "chart.png";
			link.href = canvas.toDataURL("image/png");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

</script>